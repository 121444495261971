import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import AddAdmin from "../components/AddAdmin";
import {deleteDoc, doc, onSnapshot, updateDoc} from "firebase/firestore";
import {db, dbAdmins} from "../firebase";
import {dateFormatter} from "../commons/basics";
import {confirmAction, message} from "../commons/swal";

function Admins(props) {
    const [showAddAdmin,setShowAdmin] = useState(false)
    const [admins,setAdmins] = useState([])
    const [selectedAdmin,setSelectedAdmin] = useState(undefined)
    const getAdmins = ()=>{
        onSnapshot(dbAdmins,snapshot => {
            let tempAdmins = []
            snapshot.docs.forEach(doc=>{
                tempAdmins.push({
                    ...doc.data(),email:doc.id
                })
            })
            setAdmins(tempAdmins)
        })
    }
    useEffect(()=>{
        getAdmins()
    },[])
    const editAdmin = (admin) => {
        setSelectedAdmin(admin)
        setShowAdmin(true)
    }
    const removeAdmin = async (admin) => {
        if(!await confirmAction('remove admin'))return
        try{
            await deleteDoc(doc(db, 'admins', admin.email))
            message({icon: 'success', title: 'Admin removed'})
        }catch (e){
            message({icon: 'error', title: 'An error occurred'})
            console.log(e)
        }
    }
    const editStatus = async (admin) => {
        updateDoc(doc(db,'admins',admin.email),{
            isActive:!admin.isActive
        })

    }
    return (
        <div className='p-8'>
            <div className="flex justify-between items-center">
                <h1 className='text-6'>Admins</h1>
                <button className="w-150px h-8 rounded-md flex gap-1 items-center justify-center bg-primary text-white"
                        onClick={()=>{setShowAdmin(true)}}
                >
                    Add admin
                </button>
            </div>
            {
                showAddAdmin &&
                <AddAdmin change={setShowAdmin} selectedAdmin={selectedAdmin}/>
            }
            <div className='mt-4 border-t-1px pt-4'>
                <div className="flex flex-wrap gap-4">
                    {
                        admins.map(admin=>(
                            <div className='w-300px h-200px shadow-md border-t-1px rounded-md p-4 bg-white dark:bg-slate-700 dark:text-white' key={admin.email}>
                                <div className="flex justify-between">
                                    {
                                        admin.isActive?
                                            <span className='bg-green-300 text-white px-2 '>Active</span>
                                            :
                                            <span className='bg-primary text-white px-2 '>Inactive</span>
                                    }
                                    <span>
                                        {
                                            dateFormatter(admin.dateCreated,'short-slash')
                                        }

                                    </span>
                                    <div className="dropdown-cont relative">
                                       <span className="">
                                            <FontAwesomeIcon icon={solid('ellipsis-vertical')} />
                                       </span>
                                        <div className="absolute rounded-md top-full right-0 -mr-2 w-200px shadow-lg border-t-1px bg-white dropdown dark:bg-slate-800 dark:text-white">
                                            <div className="absolute bottom-full h-10 w-10 right-0"></div>
                                            <button className='w-full hover:bg-secondary hover:text-white h-10'
                                                    onClick={()=>{editStatus(admin)}}
                                            >
                                                {
                                                    admin.isActive?
                                                        'Set Inactive':
                                                        'Set Active'
                                                }

                                            </button>
                                            <button className='w-full hover:bg-secondary hover:text-white h-10'
                                            onClick={()=>{editAdmin(admin)}}
                                            >
                                                Edit
                                            </button>
                                            <button className='w-full hover:bg-secondary hover:text-white h-10' onClick={()=>{removeAdmin(admin)}}> Remove </button>

                                        </div>
                                    </div>
                                </div>
                                <div className='text-ce'>
                                    <img src={require('../assets/images/avatar.png')} alt="" className='h-32 w-32 rounded-full mx-auto'/>
                                </div>
                                <div className="font-semibold text-center">
                                    {admin.firstName} {admin.lastName}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default Admins;
