import React, {useEffect, useState} from 'react';
import {Chart} from "react-google-charts";
import {getDocs, query, where} from "firebase/firestore";
import {dbOrders} from "../firebase";
import orders from "../pages/orders/Orders";
export const data = [
    ["Year", "Sales", "Expenses"],
    ["2004", 1000],
    ["2005", 1170],
    ["2006", 660],
    ["2007", 1030],
    ["2006", 660],
    ["2007", 1030],
    ["2008", 1000],
    ["2009", 1170],
    ["2010", 660],
    ["2011", 1030],
    ["2013", 660],
    ["2014", 1030],
];

export const options = {
    // title: "Company Performance",
    // curveType: "function",
    legend: { position: "bottom" },
};
let months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]

function SalesChart(props) {

    const [data,setData] = useState([
        ['Month', 'Total sales'],
        ["2004", 1000],
        ["2005", 1170],
        ["2006", 660],
        ["2007", 1030],
        ["2006", 660],
    ])
    const getSales = async () => {
        let docs = []
        let date = new Date()
        let startMonth = date.getMonth()
        let rows = []
        for (let i = startMonth; i < 12+startMonth; i++) {
            let year=date.getFullYear(),a =i,totalSales=0
            if(i>11){
                a = i-12
            }
            else {
                year-=1
            }
            let q
            if (i==11){
                const x = Date.parse(`${months[a]} 1 ${year}`).toString()
                const y = Date.parse(`${months[0]} 1 ${year+1}`) .toString()
                q = query(dbOrders,
                    where('date','>=',x),
                    where('date','<',y),
                    // where('isDelivered','==',true)
                )

            }
            else {
                const x = Date.parse(`${months[a]} 1 ${year}`).toString()
                const y = Date.parse(`${months[a+1]} 1 ${year}`).toString()
                q = query(dbOrders,
                    where('date','>=',x),
                    where('date','<',y),
                    // where('isDelivered','==',true)
                )
            }
            docs =(await getDocs(q)).docs
            if(docs){
                docs.forEach(order => {
                    totalSales += order.data().totalPrice
                })
                rows.push([`${months[a]} ${year}`, totalSales])
            }else{
                // console.log(`no doc in ${months[a]}`)
            }
        }
        setData([
            ['Month', 'Total sales'],
            ...rows
        ])

    }
    useEffect(()=>{
        getSales()

    },[])
    return (
        <Chart
            chartType="LineChart"
            data={data}
            options={options}
            width={"100%"}
            height={"400px"}
        />
    );
}

export default SalesChart;
