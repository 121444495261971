import React, {useEffect, useRef, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {message} from "../commons/swal";
import {doc, updateDoc, setDoc} from 'firebase/firestore'
import {auth, db, dbProducts, storage, storageRef, uploadBytes} from "../firebase";
import {getDownloadURL,listAll,deleteObject} from 'firebase/storage'
import {formIsValid, removeFromArray} from "../commons/basics";
import customers from "../pages/customers/Customers";
import {getAuth,sendSignInLinkToEmail} from 'firebase/auth'


function AddAdmin({change,selectedAdmin}) {
    const editing = selectedAdmin?true:false
    const accessibles = ['products','orders','offers','banners','customers','admins','shipping fee']
    const [user,setUser] = useState({
        access:accessibles
    })
    useEffect(()=>{
        if(selectedAdmin){
            console.log('here')
            let temp = {
                ...selectedAdmin,
                name :selectedAdmin.firstName+' '+ selectedAdmin.lastName,
            }
            setUser(temp)
        }

    },[selectedAdmin])


    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    hideEl();
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef)
    const createAdmin = async (event) => {
        if(!formIsValid('admin-form'))return
        const actionCodeSettings = {
            // url: 'https://wincos.co.ke/',
            url: 'https://dashboard.wincos.co.ke/?email='+user.email,
            // This must be true.
            handleCodeInApp: true,

        }
        try{
            let thisUser = {...user}
            delete  thisUser.email
            let names = user.name.split(' ')
            thisUser = {
                ...thisUser,
                firstName:names[0],
                lastName:names[1],
                dateCreated:(new Date()).getTime(),
                isActive:true
            }
            delete thisUser.name
            await sendSignInLinkToEmail(auth, user.email, actionCodeSettings)
            await setDoc(doc(db,'admins',user.email),thisUser)
            message({icon:'success',title:'Admin has been created'})
            hideEl()

        }   catch (e){
            console.log(e)
        }

    }
    const saveAdmin = async (event)=>{
        if(!formIsValid('admin-form'))return
        try{
            let thisUser = {...user}
            delete  thisUser.email
            let names = user.name.split(' ')
            thisUser['firstName'] = names[0]
            thisUser['lastName'] = names[1]
            delete thisUser.name
            await setDoc(doc(db,'admins',user.email),thisUser)
            message({icon:'success',title:'Changes successfully changes'})
            hideEl()

        }   catch (e){
            message({icon:'error',title:'Error saving changes'})
        }

    }
    const hideEl = () => {
        wrapperRef.current.classList.replace('animate__fadeInRight','animate__fadeOutRight')
        setTimeout(()=>{
            change(false)
        },500)
    }
    const manageAccessibles = (access)=>{
        let myAccess = [...user.access]
        if(myAccess.includes(access)){
            myAccess = removeFromArray(myAccess,access)

        }   else {
            myAccess.push(access)

        }
        setUser(user=>{return{...user,access: myAccess}})

    }
    return (
        <div className='fixed w-full h-screen-h flex justify-center bg-gray-500/75 top-0 left-0 z-20 overflow-hidden items-center'>
            <div className="h-fit w-full lg:w-400px min-w-200px bg-white dark:bg-slate-700 dark:text-white animate__animated animate__fadeInRight flex flex-col" ref={wrapperRef}>
                <div className="h-16 min-h-16 flex items-center justify-between px-4 bg-slate-100  dark:bg-gray-500">
                    <div className=''>
                        <h1 className="text-5">Add admin</h1>
                    </div>
                    <button className="h-10 w-10 bg-white dark:bg-slate-700 dark:text-white shadow-md rounded-full" onClick={hideEl}>
                        <FontAwesomeIcon icon={solid('xmark')}/>
                    </button>
                </div>
                <form onSubmit={(event)=>event.preventDefault()} id='admin-form' className='h-full overflow-y-auto pb-10'>
                    <div className="grid lg:grid-cols-3 p-4 gap-1 mb-4">
                        <div className="col-span-1 font-semibold ">
                            Name
                        </div>
                        <div className="col-span-2 max-w-full">
                            <input type="text" className='border-1px rounded-md pl-4 w-full h-8 required dark:bg-slate-500'
                                   value={user.name}  id='name'
                                   onChange={(event) => setUser(user=>{return {...user,name:event.target.value}})}
                            />

                        </div>
                    </div>
                    <div className="grid lg:grid-cols-3 p-4 gap-1 mb-4">
                        <div className="col-span-1 font-semibold ">
                            Email
                        </div>
                        <div className="col-span-2 max-w-full">
                            <input type="text" className='border-1px rounded-md pl-4 w-full h-8 dark:bg-slate-500'
                                   value={user.email}  id='email'
                                   onChange={(event) => setUser(user=>{return {...user,email:event.target.value}})}
                                   disabled={editing}
                            />

                        </div>
                    </div>

                    <div className="grid lg:grid-cols-3 p-4 gap-1 mb-4">
                        <div className="col-span-1 font-semibold ">
                            Will have access to:
                        </div>
                        <div className="col-span-2 max-w-full">
                                <div className="flex gap-1 items-center">
                                    {
                                        user.access.length === accessibles.length?
                                        <button type='button'
                                                className=" w-16 h-6 rounded-pill border-1px border-primary"
                                                onClick={() => {
                                                    setUser(user=>{return{...user,access: accessibles.slice(0,3)}})
                                                }}
                                        >
                                            <span
                                                className="block h-5 w-5 bg-primary rounded-full float-right mr-1"></span>
                                        </button>
                                            :<button type='button'
                                                     className=" w-16 h-6 rounded-pill border-1px border-slate-200"
                                                     onClick={() => {
                                                         setUser(user=>{return{...user,access: [...accessibles]}})
                                                     }}
                                            >
                                                <span
                                                    className="block h-5 w-5 bg-slate-200 rounded-full ml-1
                                                    animate__animated"
                                                >

                                                </span>
                                            </button>
                                    }
                                    <span>Everything</span>
                                </div>
                                <div className='relative mt-4 grid  gap-2'>
                                    {
                                        user.access.length === accessibles.length&&
                                        <div className="absolute top-0 bottom-0 w-full bg-slate-100/20"></div>
                                    }
                                    <div className='flex items-center gap-2'>

                                        {
                                            user.access.includes('products')?
                                                <button type='button'
                                                        className=" w-10 h-5 rounded-pill border-1px border-primary"
                                                        onClick={() => {manageAccessibles('products')}}>
                                                    <span  className="block h-3 w-3 bg-primary rounded-full float-right mr-1"></span>
                                                </button>:
                                                <button type='button'
                                                        className=" w-10 h-5 rounded-pill border-1px border-slate-200"
                                                        onClick={() => {manageAccessibles('products')}}>
                                                    <span  className="block h-3 w-3 bg-slate-200 rounded-full ml-1"></span>
                                                </button>
                                        }
                                        <span>
                                            Manage Products
                                        </span>
                                    </div>
                                    <div className='flex items-center gap-2'>

                                        {
                                            user.access.includes('orders')?
                                                <button type='button'
                                                        className=" w-10 h-5 rounded-pill border-1px border-primary"
                                                        onClick={() => {manageAccessibles('orders')}}>
                                                    <span  className="block h-3 w-3 bg-primary rounded-full float-right mr-1"></span>
                                                </button>:
                                                <button type='button'
                                                        className=" w-10 h-5 rounded-pill border-1px border-slate-200"
                                                        onClick={() => {manageAccessibles('orders')}}>
                                                    <span  className="block h-3 w-3 bg-slate-200 rounded-full ml-1"></span>
                                                </button>
                                        }
                                        <span>
                                            Manage Orders
                                        </span>
                                    </div>
                                    <div className='flex items-center gap-2'>

                                        {
                                            user.access.includes('offers')?
                                                <button type='button'
                                                        className=" w-10 h-5 rounded-pill border-1px border-primary"
                                                        onClick={() => {manageAccessibles('offers')}}>
                                                    <span  className="block h-3 w-3 bg-primary rounded-full float-right mr-1"></span>
                                                </button>:
                                                <button type='button'
                                                        className=" w-10 h-5 rounded-pill border-1px border-slate-200"
                                                        onClick={() => {manageAccessibles('offers')}}>
                                                    <span  className="block h-3 w-3 bg-slate-200 rounded-full ml-1"></span>
                                                </button>
                                        }
                                        <span>
                                            Manage Offers
                                        </span>
                                    </div>
                                    <div className='flex items-center gap-2'>

                                        {
                                            user.access.includes('banners')?
                                                <button type='button'
                                                        className=" w-10 h-5 rounded-pill border-1px border-primary"
                                                        onClick={() => {manageAccessibles('banners')}}>
                                                    <span  className="block h-3 w-3 bg-primary rounded-full float-right mr-1"></span>
                                                </button>:
                                                <button type='button'
                                                        className=" w-10 h-5 rounded-pill border-1px border-slate-200"
                                                        onClick={() => {manageAccessibles('banners')}}>
                                                    <span  className="block h-3 w-3 bg-slate-200 rounded-full ml-1"></span>
                                                </button>
                                        }
                                        <span>
                                            Manage banners
                                        </span>
                                    </div>
                                    <div className='flex items-center gap-2'>

                                        {
                                            user.access.includes('customers')?
                                                <button type='button'
                                                        className=" w-10 h-5 rounded-pill border-1px border-primary"
                                                        onClick={() => {manageAccessibles('customers')}}>
                                                    <span  className="block h-3 w-3 bg-primary rounded-full float-right mr-1"></span>
                                                </button>:
                                                <button type='button'
                                                        className=" w-10 h-5 rounded-pill border-1px border-slate-200"
                                                        onClick={() => {manageAccessibles('customers')}}>
                                                    <span  className="block h-3 w-3 bg-slate-200 rounded-full ml-1"></span>
                                                </button>
                                        }
                                        <span>
                                            View customers
                                        </span>
                                    </div>


                                </div>
                        </div>
                    </div>
                    <div className="flex justify-end gap-4 px-4">
                        <button type='button' className="w-150px h-8 rounded-md flex gap-1 items-center justify-center bg-slate-100  dark:bg-gray-400" onClick={hideEl}>
                            Cancel
                        </button>
                        {
                            editing?
                                <button className="w-150px h-8 rounded-md flex gap-1 items-center justify-center bg-primary text-white"
                                        onClick={saveAdmin}
                                >
                                    Save
                                </button>
                                :
                                <button className="w-150px h-8 rounded-md flex gap-1 items-center justify-center bg-primary text-white"
                                        onClick={createAdmin}
                                >
                                    Create
                                </button>

                        }
                    </div>
                </form>
            </div>


        </div>
    );
}

export default AddAdmin;
