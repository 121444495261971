import React, {useEffect, useState} from 'react';
import {dateFormatter} from "../../commons/basics";
import Pagination from "../../components/Pagination";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {makeExtensible} from "../../commons/objects";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {confirmAction, message} from "../../commons/swal";
import {deleteDoc, doc, updateDoc} from "firebase/firestore";
import {db} from "../../firebase";

function Offers(props) {
    const navigate = useNavigate()
    const seeDetails = (id) => {
        navigate(`/offers/${id}`)
    }
    const {hotOffers} = useSelector(state => state.productFilters)
    const [activePage,setActivePage] = useState(1)
    const [offerPages,setOfferPages] = useState(1)
    const [offerList,setOfferList] = useState([])
    useEffect(()=>{
        let tempOffers = makeExtensible(hotOffers)
        let pageNumber = Math.round(Object.values(tempOffers).length/8)
        if (pageNumber>=1){
            setOfferPages(pageNumber)
        }else {
            setOfferPages(1)
        }

        let tempProdList = Object.values(tempOffers)
        let startIndex = (activePage-1)*8
        setOfferList(tempProdList.slice(startIndex,startIndex+8))
    },[hotOffers,activePage])
    const deleteOffer = async(id)=>{
        if(await confirmAction('delete offer')){
        //    check if offer is attatched to an order
            try{
                await deleteDoc(doc(db,'offers',id))
                message({icon:"success",title:'Offer deleted'})
            }catch {
                message({icon:"error",title:'Error deleting offer'})
            }
        }
    }
    const hideOffer = async(id,isHidden)=>{
        if(isHidden === undefined){
            seeDetails(id)
            return
        }
        try{
            await updateDoc(doc(db,'offers',id),{
                isHidden
            })
            message({icon:"success",title:'Offer visibility updated'})
        }catch {
            message({icon:"error",title:'Error updating offer'})
        }
    }
    return (
        <div className='p-8'>
            <div className="flex justify-between items-center">
                <h1 className='text-6'>Offers</h1>
                <button className="w-150px h-8 rounded-md flex gap-1 items-center justify-center bg-primary text-white" onClick={()=>{}}>
                    Add offer
                </button>
            </div>
            <div className='mt-4 border-t-1px pt-4'>
                <div className="w-fit lg:w-full p-8 bg-white dark:bg-slate-700 dark:text-white rounded-md">
                    <table className='w-full border-b-2px '>
                        <tr className='h-16  px-4 text-left'>
                            <th>ID</th>
                            <th>Discount</th>
                            <th>Start Date</th>
                            <th>Expiry date</th>
                            <th>Status</th>
                        </tr>
                        {
                            offerList.map((offer,index)=>(
                                <tr className='h-16 px-4 border-t-1px cursor-pointer hover:text-primary'
                                    key={index} >
                                    <td onClick={()=>{seeDetails(offer.id)}}>{offer.id}</td>
                                    <td onClick={()=>{seeDetails(offer.id)}}>{offer.discount}</td>
                                    <td onClick={()=>{seeDetails(offer.id)}}>{dateFormatter(offer.startDate,'short-slash')}</td>
                                    <td onClick={()=>{seeDetails(offer.id)}}>{dateFormatter(offer.endDate,'short-slash')}</td>
                                    <td>
                                        <div className="flex gap-2 text-black">
                                            <button className='h-5 w-5 rounded-sm hover:text-primary' onClick={()=>deleteOffer(offer.id)}>
                                                <FontAwesomeIcon icon={solid('trash-can')}/>
                                            </button>
                                            {
                                                offer.isHidden?
                                                <button className='h-5 w-5 rounded-sm  hover:text-primary'
                                                        onClick={()=>hideOffer(offer.id,false)}
                                                >
                                                    <FontAwesomeIcon icon={solid('eye')}/>
                                                </button>
                                                    :
                                                    <button className='h-5 w-5 rounded-sm  hover:text-primary'
                                                            onClick={()=>hideOffer(offer.id,true)}
                                                    >
                                                        <FontAwesomeIcon icon={solid('eye-slash')}/>
                                                    </button>
                                            }

                                        </div>
                                    </td>
                                </tr>
                            ))
                        }

                    </table>
                    <Pagination activePage={activePage} setActivePage={setActivePage} pageNo={offerPages}/>
                </div>

            </div>
        </div>
    );
}

export default Offers;
