import React, {useState,useEffect} from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {Pagination,Autoplay} from "swiper";
import {Swiper,SwiperSlide} from "swiper/react";
import 'swiper/css'
import {useSelector} from "react-redux";
import EditBanner from "../components/EditBanner";
import {doc, onSnapshot, setDoc} from "firebase/firestore";
import {db, dbBanners, storage, storageRef} from "../firebase";
import {getDownloadURL, uploadBytes} from "firebase/storage";
import {message} from "../commons/swal";
function Banners(props) {

    const [mainBanners,setMainBanners] = useState([])
    const [sideBanners,setSideBanners] = useState([])
    const [tempMainBanners,setTempMainBanners] = useState([])
    const [tempSideBanners,setTempSideBanners] = useState([])
    const prevImages = [1,2,3,4,5]
    const [activeSide,setActiveSide] = useState('main')
    const [activeIndex,setActiveIndex] = useState(0)
    const [showEdit,setShowEdit] = useState(false)
    const [selectedBanner,setSelectedBanner] = useState({})



    const removeBanner = (side,index) => {
        if(side = 'main'){
            setMainBanners(banners =>banners.splice(index,1))
        }else{
            setSideBanners(banners =>banners.splice(index,1))
        }
    }
    const moveBanner = (index,direction) =>{
        let tempImages = [...mainBanners]
        if(direction === 'up'){
            let temp = tempImages[index]
            tempImages[index] = tempImages[index-1]
            tempImages[index-1] = temp


        } else{
            let temp = tempImages[index]
            tempImages[index] = tempImages[index+1]
            tempImages[index+1] = temp

        }
        setMainBanners(tempImages)

    }
    const showEditBanner = (side,index) => {
        setActiveSide(side)
        setActiveIndex(index)
        if(side === 'main'){
            if(mainBanners[index] && mainBanners[index].image){
                setSelectedBanner(mainBanners[index])
            }
        }else {
            if(sideBanners[index] && sideBanners[index].image){
                setSelectedBanner(sideBanners[index])
            }
        }
        setShowEdit(true)

    }
    const saveBanner = (banner) =>{
        if(activeSide === 'main'){
            let banners = [...mainBanners]
            banners[activeIndex] = banner
            setMainBanners(banners)
        }else{
            let banners = [...sideBanners]
            banners[activeIndex] = banner
            setSideBanners(banners)
        }


    }
    const saveBanners = async() => {
        if(sideBanners.length === 0 && mainBanners.length === 0)return
        try{
            let tempMain = []
            for (let i = 0; i < mainBanners.length; i++) {
                let banner = mainBanners[i]
                if(!banner)continue
                if(typeof (banner.image) === 'object'){
                    let ref = storageRef(storage,`banners/main-banners/banner-${i}`)
                    await uploadBytes(ref,banner.image)
                    let url = await getDownloadURL(ref)
                    banner.image = url
                }
                delete banner.prevImage
                tempMain.push(banner)
            }
            await setDoc(doc(db,'banners','main-banners'),{
                banners:tempMain
            })
            message({icon:'success',title:'main banners saved'})
        }catch (e) {
            console.log(e)
            message({icon:'error',title:'error saving main banners'})

        }
        try{
            let tempSide = []
            for (let i = 0; i < sideBanners.length; i++) {
                let banner = sideBanners[i]
                if(!banner)continue
                if(typeof (banner.image) === 'object'){
                    let ref = storageRef(storage,`banners/side-banners/banner-${i}`)
                    await uploadBytes(ref,banner.image)
                    let url = await getDownloadURL(ref)
                    banner.image = url
                }
                delete banner.prevImage
                tempSide.push(banner)
            }
            await setDoc(doc(db,'banners','side-banners'),{
                banners:tempSide
            })
            message({icon:'success',title:'side banners saved'})
        }catch (e){
            console.log(e)
            message({icon:'error',title:'error saving side banners'})
        }

    }
    const discard = ()=>{
        setSideBanners(tempSideBanners)
        setMainBanners(tempMainBanners)
    }
    useEffect(()=>{
        onSnapshot(dbBanners,snapshot => {
            snapshot.docs.forEach(doc=>{
                if(doc.id === 'main-banners'){
                    let data = [...doc.data().banners]
                    try{
                        for (let i = 0; i < data.length; i++) {
                            data[i]['prevImage'] = data[i].image
                        }
                    }catch{}
                    setMainBanners(data)
                    setTempMainBanners(data)
                }else{
                    let data = [...doc.data().banners]
                    try{
                        for (let i = 0; i < data.length; i++) {
                            data[i]['prevImage'] = data[i].image
                        }
                    }catch{}
                    setSideBanners(data)
                    setTempSideBanners(data)
                }

            })
        })
    },[])
    useEffect(()=>{
        if(!showEdit){
            setSelectedBanner({})
        }
    },[showEdit])

    return (
        <div  className='w-90% lg:w-full mx-auto lg:p-8'>
            {
                showEdit &&
                <EditBanner saveBanner={saveBanner} showEdit={setShowEdit} selectedBanner={selectedBanner}/>
            }
            <div className="flex flex-col lg:flex-row justify-between">
                <h1 className='text-6'>Banners</h1>
            </div>
            <div className="mt-4 border-t-1px pt-4">
                <div className="flex gap-10">
                    <div className="categories min-w-20% border-1px bg-white hidden lg:block min-h-300px rounded-lg">
                        <div className="h-10 flex items-center justify-center bg-amber-400  rounded-tr-lg
               rounded-tl-lg uppercase font-bold">
                            All categories
                        </div>
                        <div>
                            <Link href='/categories/laptops'>
                                <a className='block h-10 w-full px-4 flex items-center gap-2 hover:bg-secondary hover:text-white'>
                              <span className='text-gray-500'>
                                  <FontAwesomeIcon icon={solid('display')}/>
                              </span>
                                    <span>Laptops</span>
                                </a>
                            </Link>
                            <Link href='/categories/phones'>
                                <a className='block h-10 w-full px-4 flex items-center gap-2 hover:bg-secondary hover:text-white'>
                              <span className='text-gray-500'>
                              <FontAwesomeIcon icon={solid('mobile-screen')}/>
                          </span>
                                    <span>Phones</span>
                                </a>
                            </Link>
                            <Link href='/categories/audio'>
                                <a className='block h-10 w-full px-4 flex items-center gap-2 hover:bg-secondary hover:text-white'>
                            <span className='text-gray-500'>
                                <FontAwesomeIcon icon={solid('headphones')}/>
                            </span>
                                    <span>Audio</span>
                                </a>
                            </Link>
                            <Link href='categories/accessories'>
                                <a className='block h-10 w-full px-4 flex items-center gap-2 hover:bg-secondary hover:text-white'>
                             <span className='text-gray-500'>
                              <FontAwesomeIcon icon={solid('plug')}/>
                          </span>
                                    <span>Accessories</span>
                                </a>
                            </Link>
                        </div>
                    </div>
                    <div className="lg:w-55%">
                        <div className='h-250px lg:h-400px w-full max-w-800px mx-auto'>
                            <Swiper
                                className='mt-2 relative rounded-lg h-full'
                                loop={true} spaceBetween={4}
                                containerModifierClass={"carousel carousel-"}
                                pagination={{
                                    el:'.page-bullet-cont',
                                    clickable:true,
                                    bulletClass:'page-bullet',
                                    bulletActiveClass:'page-bullet-active',}}
                                modules={[Pagination,Autoplay]}
                                slideActiveClass='swiper-slide-active animate__slideInRight'
                                slidePrevClass='swiper-slide-duplicate-prev slide animate__slideOutLeft'
                                speed={1000}
                                autoplay={{
                                    delay:4000
                                }}
                                grabCursor={true}
                            >
                                {
                                    [1,2,3,4,5].map((slide,index)=>(
                                        <SwiperSlide className='h-full rounded-lg max-h-200px lg:max-h-350px overflow-y-hidden' key={index}>
                                            <div className="h-full w-full flex items-center justify-center rounded-lg bg-secondary flex items-center relative" key={index}>
                                                <div className="w-50% h-80% max-h-200px flex items-end justify-center z-1">
                                                    <Link href=''>
                                                        <a className='z-2'>
                                                            <button className="w-150px rounded-md h-8 bg-gradient-to-r from-primary to-secondary border-1px text-white">
                                                                Shop now
                                                            </button>
                                                        </a>
                                                    </Link>
                                                </div>
                                                <div className="h-full flex items-center absolute h-full w-full top-0 left-0">
                                                    {
                                                        mainBanners[index]  &&
                                                        <img src={mainBanners[index].prevImage} alt="" className='h-full w-full rounded-lg' />
                                                    }
                                                </div>

                                            </div>
                                        </SwiperSlide>
                                    ))
                                }
                                <div className="w-full page-bullet-cont z-1 h-10 bottom-0 mb absolute left-0 flex justify-center items-center" id='bullets-cont'></div>
                            </Swiper>
                        </div>
                    </div>
                    <div className="min-w-20% hidden lg:block  grid">
                        <div className="bg-amber-400 rounded-lg mb-4 h-48% overflow-hidden relative" >
                            {
                                sideBanners.length >0 &&
                                <img src={sideBanners[0].prevImage} alt=""
                                     className='h-full w-full  rounded-lg absolute'/>
                            }
                        </div>
                        <div className="bg-secondary rounded-lg h-48% overflow-hidden relative">
                            {
                                sideBanners.length >1 &&
                                <img src={sideBanners[1].prevImage} alt="" className='h-full w-full rounded-lg  absolute'/>
                            }
                        </div>
                    </div>

                </div>
                <div className='mt-10 border-t-1px flex justify-between pr-16'>
                    <div className=''>
                        <h1 className='text-5 my-4'>Main carousel banners</h1>
                        <div className=''>
                            {
                                [1,2,3,4,5].map((banner,index)=>(
                                    <div className='h-100px rounded-lg max-h-100px mb-4 relative flex gap-4' key={index}>
                                        <div className='w-300px relative h-100px dropdown-cont'>
                                            <div className="dropdown absolute top-0 left-0 w-300px h-100px bg-slate-200/50 flex items-center justify-center gap-4 z-2">
                                                <button className='h-8 w-8 border-1px rounded-sm' title='replace'
                                                        onClick={()=>showEditBanner('main',index)}>
                                                    <FontAwesomeIcon icon={solid('pencil')}/>
                                                </button>
                                                <button className='h-8 w-8 border-1px rounded-sm' title='delete'
                                                        onClick={()=>removeBanner('main',index)}
                                                >
                                                    <FontAwesomeIcon icon={solid('trash-can')}/>
                                                </button>
                                            </div>


                                            <div className='h-full w-full flex items-center justify-center rounded-lg border-1px flex items-center'>
                                                <div className="h-full flex items-center absolute h-full w-full top-0 left-0 z-1">
                                                    {
                                                        mainBanners[index]  &&
                                                        <img src={mainBanners[index].prevImage} alt="" className='h-full w-full rounded-lg' />
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                        {
                                            mainBanners[index]  &&
                                            <div className='flex flex-col justify-center gap-2'>
                                                <h1> Banner for</h1>
                                                <div>
                                                        <>
                                                            {
                                                                mainBanners[index].bannerCat !== 'Hot offers'?
                                                                    <span className='h-full w-full rounded-lg' >{mainBanners[index].choice}</span>
                                                                    :
                                                                    <span className='h-full w-full rounded-lg' >{mainBanners[index].bannerCat}</span>
                                                            }
                                                        </>
                                                </div>

                                            </div>
                                        }
                                        <div className="absolute left-full top-0 h-full flex flex-col justify-center pl-4 gap-2">
                                            {
                                                index+1 > 1 &&
                                                <button className='h-8 w-8 border-1px rounded-sm' title='move up'
                                                        onClick={() => moveBanner(index, 'up')}
                                                >
                                                    <FontAwesomeIcon icon={solid('arrow-up')}/>
                                                </button>
                                            }
                                            {
                                                index+1 < prevImages.length &&
                                                <button className='h-8 w-8 border-1px rounded-sm' title='Move down'
                                                        onClick={()=>moveBanner(index,'down')}
                                                >
                                                    <FontAwesomeIcon icon={solid('arrow-down')}/>
                                                </button>
                                            }

                                        </div>

                                    </div>
                                ))
                            }
                            <div id="img-test" className='hidden'></div>

                        </div>
                    </div>
                    <div>
                        <h1 className='text-5 my-4'>Side banners</h1>
                        <div className='w-300px'>
                            {
                                [1,2].map((banner,index)=>(
                                    <div className='flex gap-2' key={index}>
                                        <div className='w-full h-100px rounded-lg max-h-100px mb-4 relative dropdown-cont' key={index}>
                                            <div className="dropdown absolute top-0 left-0 h-full w-full bg-slate-200/50 flex items-center justify-center gap-4 z-1">
                                                <button className='h-8 w-8 border-1px rounded-sm' title='replace'
                                                        onClick={()=> {
                                                            showEditBanner('side', index)
                                                        }}>
                                                    <FontAwesomeIcon icon={solid('pencil')}/>
                                                </button>
                                                <button className='h-8 w-8 border-1px rounded-sm' title='delete'
                                                        onClick={()=>removeBanner('side',index)}
                                                >
                                                    <FontAwesomeIcon icon={solid('trash-can')}/>
                                                </button>
                                            </div>
                                            <div className='h-full w-full flex items-center justify-center rounded-lg border-1px flex items-center relative'>
                                                <div className="w-50% h-80% max-h-200px flex items-end justify-center absolute">

                                                </div>
                                                <div className="w-full h-full flex items-center ">
                                                    {
                                                        sideBanners[index]  &&
                                                        <img src={sideBanners[index].prevImage} alt="" className='h-full w-full rounded-lg' />
                                                    }
                                                </div>

                                            </div>

                                        </div>
                                        <div>
                                            <h1>
                                                Banner for
                                            </h1>

                                        </div>
                                    </div>
                                ))
                            }

                            <div id="img-test" className='hidden'></div>

                        </div>
                    </div>

                </div>
                <div className='mt-4 flex justify-end gap-4'>
                    <button className='h-8 w-150px bg-slate-100 rounded-sm' onClick={discard}>
                        Discard
                    </button>
                    <button className='h-8 w-150px bg-primary rounded-sm text-white' onClick={saveBanners}>
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Banners;
