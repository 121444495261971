import React, {useEffect, useRef, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {message} from "../commons/swal";
import {doc, updateDoc, setDoc, addDoc} from 'firebase/firestore'
import {auth, db, dbShipping,} from "../firebase";


function AddShippingFee({change,selectedFee}) {
    const [fee,setFee] = useState({
        town:'',
        charges:50,
        isActive:true,
    })
    const updateCharges = (charges)=>{
        if(charges === '') {
            charges = 0
        }
        charges = parseInt(charges)
        setFee((fee)=>({
            ...fee,
            charges
        }))
    }
    useEffect(()=>{
        if(selectedFee){
            setFee(selectedFee)
        }
    },[selectedFee])



    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    hideEl();
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef)

    const hideEl = () => {
        wrapperRef.current.classList.replace('animate__fadeInRight','animate__fadeOutRight')
        setTimeout(()=>{
            change(false)
        },500)
    }

    const updateFee = async ()=>{
        let id = fee.id
        delete fee.id
        try{
            await updateDoc(doc(db, 'shipping-fee', id), {
                ...fee
            })
            message({icon:'success',title:"shipping fee updated"})
            hideEl()
        }catch {
            message({icon:'error',title:"An error occurred"})
        }

    }
    const addFee = async ()=>{
        try{
            await addDoc(dbShipping,fee)
            message({icon:'success',title:"shipping fee added"})
            hideEl()
        }catch {
            message({icon:'error',title:"An error occurred"})
        }

    }

    return (
        <div className='fixed w-full h-screen-h flex justify-center bg-gray-500/75 top-0 left-0 z-20 overflow-hidden items-center'>
            <div className="h-fit w-full lg:w-400px min-w-200px bg-white dark:bg-slate-700 dark:text-white animate__animated animate__fadeInRight flex flex-col" ref={wrapperRef}>
                <div className="h-16 min-h-16 flex items-center justify-between px-4 bg-slate-100  dark:bg-gray-500">
                    <div className=''>
                        <h1 className="text-5">{selectedFee?"Add":'Edit'} Shipping Fee</h1>
                    </div>
                    <button className="h-10 w-10 bg-white dark:bg-slate-700 dark:text-white shadow-md rounded-full" onClick={hideEl}>
                        <FontAwesomeIcon icon={solid('xmark')}/>
                    </button>
                </div>
                <form onSubmit={(event)=>event.preventDefault()} id='admin-form' className='h-full overflow-y-auto pb-10'>
                    <div className="grid lg:grid-cols-3 p-4 gap-1 mb-4">
                        <div className="col-span-1 font-semibold ">
                            Town
                        </div>
                        <div className="col-span-2 max-w-full">
                            <input type="text" className='border-1px rounded-md pl-4 w-full h-8 required dark:bg-slate-500'
                                   value={fee.town}  id='name'
                                   onChange={(event) => setFee(fee=>{return {...fee,town:event.target.value}})}
                            />

                        </div>
                    </div>
                    <div className="grid lg:grid-cols-3 p-4 gap-1 mb-4">
                        <div className="col-span-1 font-semibold ">
                            Charges
                        </div>
                        <div className="col-span-2 max-w-full">
                            <input type="number" className='border-1px rounded-md pl-4 w-full h-8 dark:bg-slate-500'
                                   value={fee.charges}  id='email'
                                   onChange={(event) => updateCharges(event.target.value)}
                            />

                        </div>
                    </div>
                    <div className="grid lg:grid-cols-3 p-4 gap-1 mb-4">
                        <div className="col-span-1 font-semibold ">
                            Is Active
                        </div>
                        <div className="col-span-2 max-w-full">
                            {
                                fee.isActive?
                                    <button type='button'
                                            className=" w-16 h-6 rounded-pill border-1px border-primary"
                                            onClick={() => {
                                                setFee(fee=>{return{...fee,isActive:false}})
                                            }}
                                    >
                                            <span
                                                className="block h-5 w-5 bg-primary rounded-full float-right mr-1"></span>
                                    </button>
                                    :<button type='button'
                                             className=" w-16 h-6 rounded-pill border-1px border-slate-200"
                                             onClick={() => {
                                                 setFee(fee=>{return{...fee,isActive:true}})
                                             }}
                                    >
                                                <span
                                                    className="block h-5 w-5 bg-slate-200 rounded-full ml-1
                                                    animate__animated"
                                                >

                                                </span>
                                    </button>
                            }

                        </div>
                    </div>

                    <div className="flex justify-end gap-4 px-4">
                        <button type='button' className="w-150px h-8 rounded-md flex gap-1 items-center justify-center bg-slate-100  dark:bg-gray-400" onClick={hideEl}>
                            Cancel
                        </button>
                        {
                            selectedFee?
                                <button className="w-150px h-8 rounded-md flex gap-1 items-center justify-center bg-primary text-white"
                                        onClick={updateFee}
                                >
                                    Save
                                </button>
                                :
                                <button className="w-150px h-8 rounded-md flex gap-1 items-center justify-center bg-primary text-white"
                                        onClick={addFee}
                                >
                                    Create
                                </button>

                        }
                    </div>
                </form>
            </div>


        </div>
    );
}

export default AddShippingFee;
