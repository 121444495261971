import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {useSelector} from "react-redux";
import Stars from "../../components/Stars";
import {priceFormatter} from "../../commons/basics";
import {Quill} from "react-quill";

function ProductDetails(props) {
    const {id: prodId} = useParams()
    const {products,brands,categories} = useSelector(state => state.products)
    const [product,setProduct] = useState(undefined)
    const [detailsView,setDetailsView] = useState('Specifications')
    const [selectedImage,setSelectedImage] = useState('')
    useEffect(()=>{
        if(products){
            setProduct(products[prodId])
        }
    },[products,prodId])
    useEffect(()=>{
        if(product){
            try{
                let tempSpecCont = document.createElement('div')
                let specCont = document.getElementById('specs-cont')
                specCont.appendChild(tempSpecCont)
                if(tempSpecCont.children.length === 0){
                    let quill = new Quill(tempSpecCont, {
                        readOnly:true,
                        modules: {
                            toolbar: []
                        },
                        placeholder: 'No specifications yet'
                    })
                    quill.setContents(product.specifications)
                    let list = tempSpecCont.getElementsByTagName('ul')[0]
                    list.classList.add('list-disc')
                    specCont.appendChild(list)
                    specCont.removeChild(tempSpecCont)

                }
                let tempInboxCont = document.createElement('div')
                let inboxCont = document.getElementById('inbox-cont')
                inboxCont.appendChild(tempInboxCont)
                if(tempInboxCont.children.length === 0){
                    let quill = new Quill(tempInboxCont, {
                        readOnly:true,
                        modules: {
                            toolbar: ['']
                        },
                        placeholder: 'Nothing in the box'
                    })
                    quill.setContents(product.inBox)
                    let list = tempInboxCont.getElementsByTagName('ul')[0]
                    list.classList.add('list-disc')
                    inboxCont.appendChild(list)
                    inboxCont.removeChild(tempInboxCont)

                }
            }catch (e){

            }

        }

    },[product])
    useEffect(()=>{
        if(product && product.images.length>0 && product.images[0]!== ''){
            setSelectedImage(product.images[0])
        }

    },[product])
    return (
        <div className='w-90% lg:w-full mx-auto pb-8 lg:p-8 '>
            <div className="flex flex-col lg:flex-row justify-between">
                <h1 className='text-6'>Product Details: {prodId}</h1>
                <div>
                    <Link to='/products'>
                        <button className="px-2 rounded-md border-1px h-8 whitespace-nowrap">
                            <FontAwesomeIcon icon={solid('arrow-left')} className='mr-1'/>
                            Back to products
                        </button>
                    </Link>

                </div>
                <div></div>
            </div>
            {
                product?
                    <div className="mt-4 border-t-1px pt-4">
                        <div className="flex flex-col lg:flex-row gap-4 bg-white dark:bg-slate-700 rounded-md p-4 lg:items-center">
                            <div className="w-full flex items-center">
                                <div className="min-w-120px grid gap-1 h-fit">
                                    {   (product.images[0] !== '') &&
                                        product.images.map((image,index) =>(
                                            <span key={index}>
                                                {
                                                    image === selectedImage?
                                                        <div className='h-80px w-80px rounded-md shadow-md border-t-1px flex items-center justify-center cursor-pointer'

                                                        >
                                                            <img src={image} alt=""/>
                                                        </div>
                                                        :
                                                        <div className='h-80px w-80px rounded-md hover:shadow-md hover:border-t-1px flex items-center justify-center cursor-pointer'
                                                             onClick={()=>setSelectedImage(image)}

                                                        >
                                                            <img src={image} alt=""/>
                                                        </div>
                                                }
                                            </span>
                                        ) )
                                    }
                                </div>
                                <div className="flex items-center justify-center">
                                    {
                                        product.images[0] === '' || product.images.length === 0?
                                            <img src={require('../../assets/images/products/hplaptop3.png')} alt=""/>:
                                            <img src={selectedImage} alt=""/>
                                    }
                                </div>
                            </div>
                            <div className='w-full'>
                                <div>
                                    <h1 className='text-5 lg:text-6 font-semibold'>{product.name}</h1>
                                    <div className="mt-2 flex gap-2 ">
                                        <Stars rating={3.5}/>
                                        <span>(1200 reviews)</span>
                                    </div>
                                    <div className="mt-2">
                                        {
                                            true?
                                                <span className='bg-green-300 text-green-500 px-1 rounded-sm'>In stock</span>
                                                :
                                                <span className='bg-green-300 text-green-500 px-1 rounded-sm'>out of stock</span>
                                        }
                                    </div>
                                </div>

                                <div>
                                    <div className="text-5 lg:text-6 font-semibold mt-4">
                                        ksh {priceFormatter(product.price)}
                                    </div>
                                    <div className="mt-2">
                                        {product.overview}
                                    </div>
                                </div>
                                <div className="mt-4 flex justify-between">
                                    <div className='w-full'>
                                        <div className="font-semibold">Available stock</div>
                                        <div className="">{priceFormatter(456)}</div>
                                    </div>
                                    <div  className='w-full'>
                                        <div className="font-semibold">Total orders</div>
                                        <div className="">{priceFormatter(1200)}</div>
                                    </div>
                                    <div  className='w-full'>
                                        <div className="font-semibold">Revenue</div>
                                        <div className="">Kshs {priceFormatter(1200*product.price)}</div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="mt-4 w-fit border-1px border-primary rounded-md overflow-hidden">
                            {
                                ['Specifications','Reviews'].map((view,index)=>(
                                    <span key={index} className=''>
                                        {
                                            detailsView === view?
                                                <button className='h-8 w-fit px-2 inline-flex items-center bg-primary text-white'>
                                                    {view}
                                                </button>
                                                :
                                                <button className='h-8 w-fit px-2 inline-flex items-center' onClick={()=>setDetailsView(view)}>
                                                    {view}
                                                </button>
                                        }
                                    </span>

                                ))
                            }
                        </div>
                        <div className="mt-4">
                            {
                                detailsView !== 'Reviews' ?
                                    <div className="flex flex-col lg:flex-row gap-4  mt-4 ">
                                        <div className="w-full bg-white dark:bg-slate-700 rounded-md border-1px p-4">
                                            <h1>Product Specifications</h1>
                                            <div className='mt-4 list-disc pl-2' id='specs-cont'>

                                            </div>
                                        </div>
                                        <div className="w-full bg-white dark:bg-slate-700 rounded-md border-1px p-4">
                                            <h1>What is in the box</h1>
                                            <div className='list-disc pl-2' id='inbox-cont'>

                                            </div>
                                        </div>


                                    </div>
                                    :
                                    <div className="flex flex-col lg:grid grid-cols-3 gap-4  mt-4 ">
                                        <div className="w-full bg-white dark:bg-slate-700 rounded-md border-1px p-4 col-span-1 h-fit">
                                            <div className="">
                                                <h1 className="text-5 lg:text-6 font-semibold">
                                                    3.8
                                                </h1>
                                                <p className='text-gray-500 text-3'>Based on {priceFormatter(1234)} ratings</p>
                                                <div>
                                                    <Stars rating={3.8}/>
                                                </div>
                                                <div className="mt-4">
                                                    <div>
                                                        <div className='flex justify-between'>
                                                            <div>
                                                                5
                                                                <FontAwesomeIcon icon={solid('star')} className='text-yellow-300'/>
                                                            </div>
                                                            <div className='text-gray-500 text-3'>
                                                                {priceFormatter(1176)}
                                                            </div>
                                                        </div>
                                                        <div className="bg-gray-300 rounded-pill h-2">
                                                            <div className="h-full bg-green-400 rounded-pill w-65%"></div>
                                                        </div>

                                                    </div>
                                                    <div className='mt-2'>
                                                        <div className='flex justify-between'>
                                                            <div>
                                                                4
                                                                <FontAwesomeIcon icon={solid('star')} className='text-yellow-300'/>
                                                            </div>
                                                            <div className='text-gray-500 text-3'>
                                                                {priceFormatter(794)}
                                                            </div>
                                                        </div>
                                                        <div className="bg-gray-300 rounded-pill h-2">
                                                            <div className="h-full bg-blue-400 rounded-pill w-50%"></div>
                                                        </div>

                                                    </div>
                                                    <div className='mt-2'>
                                                        <div className='flex justify-between'>
                                                            <div>
                                                                3
                                                                <FontAwesomeIcon icon={solid('star')} className='text-yellow-300'/>
                                                            </div>
                                                            <div className='text-gray-500 text-3'>
                                                                {priceFormatter(503)}
                                                            </div>
                                                        </div>
                                                        <div className="bg-gray-300 rounded-pill h-2">
                                                            <div className="h-full bg-orange-400 rounded-pill w-23%"></div>
                                                        </div>

                                                    </div>
                                                    <div className='mt-2'>
                                                        <div className='flex justify-between'>
                                                            <div>
                                                                2
                                                                <FontAwesomeIcon icon={solid('star')} className='text-yellow-300'/>
                                                            </div>
                                                            <div className='text-gray-500 text-3'>
                                                                {priceFormatter(499)}
                                                            </div>
                                                        </div>
                                                        <div className="bg-gray-300 rounded-pill h-2">
                                                            <div className="h-full bg-pink-400 rounded-pill w-15%"></div>
                                                        </div>

                                                    </div>
                                                    <div className='mt-2'>
                                                        <div className='flex justify-between'>
                                                            <div>
                                                                1
                                                                <FontAwesomeIcon icon={solid('star')} className='text-yellow-300'/>
                                                            </div>
                                                            <div className='text-gray-500 text-3'>
                                                                {priceFormatter(13)}
                                                            </div>
                                                        </div>
                                                        <div className="bg-gray-300 rounded-pill h-2">
                                                            <div className="h-full bg-red-400 rounded-pill w-8%"></div>
                                                        </div>

                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                        <div className="w-full col-span-2 grid gap-4">
                                            {
                                                [1,2,3,4,5].map((review,index)=>(
                                                    <div className="bg-white dark:bg-slate-700 rounded-md border-1px p-4" key={index}>
                                                        <div className="flex justify-between border-b-1px items-center mb-2 py-4">
                                                            <div className='flex gap-4 '>
                                                                <FontAwesomeIcon icon={solid('user')} className='text-5'/>
                                                                John Doe
                                                            </div>
                                                            <div>
                                                                <Stars rating={3.8}/>
                                                            </div>
                                                        </div>
                                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. A consequatur dolorum nemo omnis reprehenderit suscipit?
                                                    </div>
                                                ))
                                            }
                                        </div>

                                    </div>
                            }
                        </div>


                    </div>
                    :
                    <div>
                        No product
                    </div>
            }


        </div>
    );
}

export default ProductDetails;
