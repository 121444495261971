import React, {useState,useEffect} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {dateFormatter} from "../../commons/basics";
import {doc, onSnapshot} from "firebase/firestore";
import {db} from "../../firebase";

function CustomerDetails(props) {
    const  navigate = useNavigate()
    const {email} = useParams()
    const [customer,setCustomer] = useState(undefined)
    const seeDetails = (id) => {
        navigate(`/orders/${id}`)
    }
    useEffect(()=>{
        if(email){
            onSnapshot(doc(db,'customers',email),snapshot => {
                let myCustomer = {...snapshot.data(),email:snapshot.id}
                setCustomer(myCustomer)
            })
        }
    },[email])

    return (
        <>
            {
                customer?
                    <div className='w-90% lg:w-full mx-auto lg:p-8'>
                        <div className="flex flex-col lg:flex-row justify-between">
                            <h1 className='text-5 lg:text-6'>Customer details : {email}</h1>
                            <div>
                                <Link to='/customers'>
                                    <button className="px-2 rounded-md border-1px h-8">
                                        <FontAwesomeIcon icon={solid('arrow-left')} className='mr-1'/>
                                        Back to customers
                                    </button>
                                </Link>

                            </div>
                            <div></div>
                        </div>
                        <div className="mt-4 border-t-1px pt-4">
                            <div className="flex flex-col lg:flex-row gap-4">
                                <div className="w-full h-16 rounded-md flex items-center gap-4 px-4 bg-white dark:bg-slate-700 dark:text-white">
                                    <div className="h-10 w-10 rounded-md flex items-center justify-center">
                                        <FontAwesomeIcon icon={solid('calendar')}/>
                                    </div>
                                    <div>
                                        <h1>Date joined</h1>
                                        <p>
                                            {dateFormatter(new Date(customer.dateJoined).getTime(),'short-slash')}
                                        </p>
                                    </div>
                                </div>
                                <div className="w-full h-16 rounded-md flex items-center gap-4 px-4 bg-white dark:bg-slate-700 dark:text-white">
                                    <div className="h-10 w-10 rounded-md flex items-center justify-center">
                                        <FontAwesomeIcon icon={solid('user')}/>
                                    </div>
                                    <div>
                                        <h1>Name </h1>
                                        <p>
                                            {customer.firstName} {customer.lastName}
                                        </p>
                                    </div>
                                </div>
                                <div className="w-full h-16 rounded-md flex items-center gap-4 px-4 bg-white dark:bg-slate-700 dark:text-white">
                                    <div className="h-10 w-10 rounded-md flex items-center justify-center">
                                        <FontAwesomeIcon icon={solid('envelope')}/>
                                    </div>
                                    <div>
                                        <h1>Email</h1>
                                        <p>
                                            {customer.email}
                                        </p>
                                    </div>
                                </div>
                                <div className="w-full h-16 rounded-md flex items-center gap-4 px-4 bg-white dark:bg-slate-700 dark:text-white">
                                    <div className="h-10 w-10 rounded-md flex items-center justify-center">
                                        <FontAwesomeIcon icon={solid('user-check')}/>
                                    </div>
                                    <div>
                                        <h1>Status</h1>
                                        <p>
                                            202-906-12354
                                        </p>
                                    </div>
                                </div>

                            </div>
                            <div className="flex flex-col lg:grid grid-cols-3 gap-4 mt-4">
                                <div className="grid-col-start-1 col-span-2 p-4 bg-white dark:bg-slate-700 dark:text-white ">
                                    <table className='w-full border-b-2px '>
                                        <tr className='h-16  px-4 text-left'>
                                            <th>Order ID</th>
                                            <th>Date created</th>
                                            <th>Status</th>
                                        </tr>
                                        {
                                            [1,2,3,4].map((order,index)=>(
                                                <tr className='h-24 px-4 border-t-1px cursor-pointer hover:text-primary' key={index} onClick={()=>seeDetails(index)}>
                                                    <td>
                                                        Order-{index}
                                                    </td>
                                                    <td>{dateFormatter(new Date().getTime(),'short-slash')}</td>
                                                    <td> Delivered</td>
                                                </tr>
                                            ))
                                        }

                                    </table>

                                </div>
                                <div className="grid-col-start-1 bg-white dark:bg-slate-700 dark:text-white p-4">
                                    <h1 className="text-5 mb-4">
                                        Addresses
                                    </h1>
                                    {
                                        customer.addresses.map((address,index)=>(
                                            <div className='border-b-1px pt-4 -mt-1px'>
                                                <div className='flex gap-4 mb-4' key={index}>
                                                    <div className='w-16 '>
                                                        Address:
                                                    </div>
                                                    <div>
                                                        {address.address},{address.town}
                                                    </div>

                                                </div>
                                                <div className='flex gap-4 mb-4' key={index}>
                                                    <div className='w-16 '>
                                                        Phone:
                                                    </div>
                                                    <div>
                                                        {address.phone}
                                                    </div>

                                                </div>
                                                <div className='flex gap-4 mb-4' key={index}>
                                                    <div className='w-16 '>
                                                        Email:
                                                    </div>
                                                    <div>
                                                        {address.email}
                                                    </div>

                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    ''
            }
        </>
    );
}

export default CustomerDetails;
