import React, {useState,useEffect} from 'react';
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";

function EditBanner({saveBanner,showEdit,selectedBanner}) {
    const {categories,brands} = useSelector(state => state.products)
    const [image,setImage] = useState('')
    const [prevImage,setPrevImage] = useState('')
    const [bannerCat,setBannerCat] = useState('Product')
    const [choice,setChoice] = useState('')

    const selectImage = () => {
        let input=document.createElement("input");
        input.type="file";
        input.accept=".jpeg,.png,.jpg"
        let files=[]
        input.onchange = e=>{
            files=e.target.files;
            setImage(files[0])
        }
        input.click()
    }

    useEffect(()=>{
        if(image !== ''){
            if(typeof (image) === 'string'){
                setPrevImage(image)

            }else{
                let reader = new FileReader();
                reader.onload = () => {
                    setPrevImage(reader.result)
                }
                reader.readAsDataURL(image);
            }

        }

    },[image])
    const saveMYBanner = () =>{
        let banner = {
            image,
            prevImage,
            bannerCat,
            choice
        }
        saveBanner(banner)
        showEdit(false)
    }
    useEffect(()=>{
        if(selectedBanner.image){
            setBannerCat(selectedBanner.bannerCat)
            setChoice(selectedBanner.choice)
            setImage(selectedBanner.image)
            setPrevImage(selectedBanner.prevImage)
        }

    },[selectedBanner])
    return (
        <div className='fixed top-0 left-0 z-10 h-screen-h w-screen-w bg-slate-500/50 flex items-center justify-center'>
            <div className='w-500px bg-white rounded-md p-2'>
                <div className="w-full h-100px flex items-center border-1 border-dotted rounded-md ">
                    {
                        image !=='' ?
                        <div className='w-full relative flex h-full item-center justify-center dropdown-cont overflow-hidden'>
                            <img src={prevImage} alt="" className='h-100px w-full' />
                            <div className="dropdown absolute top-0 left-0 h-full w-full bg-slate-200/50 flex items-center justify-center gap-4">
                                <button className='h-8 w-8 border-1px rounded-sm' title='replace'
                                        onClick={()=>selectImage()}>
                                    <FontAwesomeIcon icon={solid('pencil')}/>
                                </button>
                            </div>
                        </div> :
                            <div className="w-full h-full text-center flex flex-col justify-center" onClick={selectImage}>
                                <FontAwesomeIcon icon={solid("cloud-arrow-up")}/>
                                <p className='my-2'>Select images here</p>
                                {/*<p className="italic text-gray-400">(Only *.jpeg,*.png and .*jpg size:300*300)</p>*/}
                            </div>
                    }
                </div>
                <div className='mt-2'>
                    <div>
                        <label className='font-semibold  mt-2 mb-1'>Banner for</label>
                        <select  className='border-1px rounded-md pl-4 w-full h-8 focus:outline-none dark:bg-slate-500'
                                 value={bannerCat}
                                 onChange={(event) => {
                                     setBannerCat(event.target.value);
                                     setChoice('')
                                 }}
                        >
                            {
                                ['Product','Hot offers','Category','Brand'].map(cat=>(
                                    <option value={cat} key={cat}>{cat}</option>
                                ))

                            }
                        </select>
                    </div>
                    <div>
                        {
                            bannerCat === 'Category'?
                            <>
                                <label className='font-semibold  mt-2 mb-1'>Select category</label>
                                <select  className='border-1px rounded-md pl-4 w-full h-8 focus:outline-none dark:bg-slate-500'
                                         value={choice}
                                         onChange={(event) => setChoice(event.target.value)}
                                >
                                    {
                                        categories.map(cat=>(
                                            <option value={cat} key={cat}>{cat}</option>
                                        ))

                                    }
                                </select>
                            </>
                                : bannerCat === 'Product' ?
                                <>
                                    <label className='font-semibold  mt-2 mb-1'>Enter product id</label>
                                    <input  className='border-1px rounded-md pl-4 w-full h-8 focus:outline-none dark:bg-slate-500'
                                             value={choice}
                                             onChange={(event) => setChoice(event.target.value)}
                                    />

                                </>
                                    : bannerCat === 'Brand'&&
                                    <>
                                        <label className='font-semibold  mt-2 mb-1'>Select brand</label>
                                        <select  className='border-1px rounded-md pl-4 w-full h-8 focus:outline-none dark:bg-slate-500'
                                                 value={choice}
                                                 onChange={(event) => setChoice(event.target.value)}
                                        >
                                            {
                                                brands.map(cat=>(
                                                    <option value={cat} key={cat}>{cat}</option>
                                                ))

                                            }
                                        </select>
                                    </>


                        }
                    </div>
                </div>
                <div className='mt-2 flex justify-end gap-2'>
                    <button className='h-8 w-100px border-1px rounded-sm' onClick={()=>{showEdit(false)}}>
                        Cancel
                    </button>
                    <button className='h-8 w-100px bg-primary rounded-sm' onClick={saveMYBanner}>
                        Save
                    </button>

                </div>
            </div>

        </div>
    );
}

export default EditBanner;
