import { createSlice } from "@reduxjs/toolkit";
const stdTime = 600
export const userSlice = createSlice({
    name:'user',
    initialState:{
        profile:{},
        session:{
            timeOut:stdTime,
            started:false
        },
        settings:{
            darkMode:false
        }
    },
    reducers:{
        writeProfile(state,action){
            state.profile = action.payload

        } ,
        writeSettings(state,action){
            state.settings = action.payload
        } ,
        reduceTimeOut(state){
            state.session.timeOut--
        } ,
        resetTimeOut(state){
            state.session.timeOut = stdTime
        } ,
        writeSessionStarted(state){
            state.session.started = true
        } ,


    }

})
export const {writeProfile,writeSettings,reduceTimeOut,resetTimeOut,writeSessionStarted} = userSlice.actions
export default userSlice.reducer
