import React, {useEffect, useState} from 'react';
import SalesChart from "../charts/SalesChart";
import CategorySales from "../charts/CategorySales";
import {WaveIcon} from "../components/SvgIcons";
import {useSelector} from "react-redux";
import '../assets/styles/charts.css'
import {limit, onSnapshot, orderBy, query} from "firebase/firestore";
import {dbOrders} from "../firebase";
import {dateFormatter} from "../commons/basics";
import BrandSales from "../charts/BrandSales";
import {useNavigate} from "react-router-dom";



function Home(props) {
    const navigate = useNavigate()
    const {darkMode} = useSelector(state => state.settings)
    const {categories} = useSelector(state => state.products)
    const [recentOrders,setRecentOrders] = useState([])

    const seekOrder = (id) => {
        navigate('/orders/'+id)
    }
    useEffect(
        ()=>{
            if(darkMode){
                try{
                    let chartDivs = document.getElementsByClassName('chart-div')
                    for (let i = 0; i < chartDivs.length; i++) {
                        let div = chartDivs[i]
                        div.classList.add('chart-dark')
                    }

                } catch (e) {

                }

            }
            else {
                try{
                    let chartDivs = document.getElementsByClassName('chart-div')
                    for (let i = 0; i < chartDivs.length; i++) {
                        let div = chartDivs[i]
                        div.classList.remove('chart-dark')
                    }

                } catch (e) {

                }

            }

        },[darkMode]
    )
    useEffect(()=>{
        let q = query(dbOrders,orderBy('date'),limit(5))
        onSnapshot(q,snapshot => {
            let tempOrders = []
            snapshot.docs.forEach(doc=>{
                let myDoc = doc
                let order = myDoc.data()
                tempOrders.push({...order, id: myDoc.id,date:order.date.seconds*1000})
            })
            setRecentOrders(tempOrders)

        })
    },[])
    return (
        <div>
            <div className="w-90% lg:w-full mx-auto flex flex-col lg:flex-row lg:px-10 gap-10">
                <div className='w-full'>
                    <div className="flex flex-col md:flex-row gap-4 ">
                        <div className="w-full h-300px rounded-md bg-slate-300 flex items-center justify-between flex-col relative py-10 ">
                            <span className="font-semibold text-5">
                                Total sales
                            </span>
                                <WaveIcon className='fill-primary h-auto w-full absolute top-50% v-center left-0 right-0'/>
                            <div>
                            </div>
                            <span className='font-bold text-5'>
                                Ksh.20,000
                            </span>
                        </div>
                        <div className="w-full h-300px rounded-md bg-primary/50 flex items-center justify-between flex-col relative py-10 ">
                            <span className="font-semibold text-5">
                                Total sales
                            </span>
                                <WaveIcon className='fill-primary h-auto w-full absolute top-50% v-center left-0 right-0'/>
                            <div>
                            </div>
                            <span className='font-bold text-5'>
                                Ksh.20,000
                            </span>
                        </div>
                        <div className="w-full h-300px rounded-md bg-secondary/75 flex items-center justify-between flex-col relative py-10 ">
                            <span className="font-semibold text-5">
                                Total sales
                            </span>
                                <WaveIcon className='fill-primary h-auto w-full absolute top-50% v-center left-0 right-0'/>
                            <div>
                            </div>
                            <span className='font-bold text-5'>
                                Ksh.20,000
                            </span>
                        </div>

                    </div>
                    <div className="mt-10 flex flex-col lg:flex-row gap-10">
                        <div className="w-full">
                            <h1 className='text-5'>Order history - last one year</h1>
                            <div className="chart-div">
                                <SalesChart/>
                            </div>
                        </div>
                        <div className="w-full">
                            <h1 className="text-5">
                                Recent orders
                            </h1>
                            <div className="mt-4">
                                <table className='w-full text-left pl-2'>
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>customer</th>
                                            <th>Date</th>
                                            <th>status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            recentOrders.map((order, index) => (
                                                <tr
                                                    className=' w-full rounded-md h-10  w-full mt-3 border-b-4 border-transparent cursor-pointer'
                                                    key={index}
                                                    onClick={()=>{seekOrder(order.id)}}
                                                >
                                                    <td className='h-full'>
                                                        <div className='border-1px rounded-tl-md rounded-bl-md  h-10 flex items-center border-r-0 pl-2'>
                                                            {index+1}
                                                        </div>
                                                    </td>
                                                    <td className='h-full'>
                                                        <div className='border-t-1px border-b-1px h-10 flex items-center'>
                                                            {order.address.email}
                                                        </div>
                                                    </td>
                                                    <td className='h-full'>
                                                        <div className='border-t-1px border-b-1px h-10 flex items-center'>
                                                            {dateFormatter(order.date,'short-slash')}
                                                        </div>
                                                    </td>
                                                    <td className='h-full'>
                                                        <div className='border-1px rounded-tr-md rounded-br-md  h-10 flex items-center border-l-0'>
                                                            Pending
                                                        </div>
                                                    </td>

                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>

                            </div>
                        </div>

                    </div>
                </div>
                <div className=' w-full lg:w-400px lg:min-w-400px border-l-1px p-2'>
                    <div>
                        <CategorySales/>
                       <BrandSales/>

                    </div>


                </div>
            </div>
        </div>
    );
}

export default Home;
