import React from 'react';
import Profile from "../components/Profile";
import PasswordChange from "../components/PasswordChange";

function Settings(props) {
    return (
        <div className='p-8'>
            <div className="grid lg:grid-cols-3 gap-4 min-h-full">
                <div className="col-span-1">
                    <div className="p-8 rounded-md bg-white dark:bg-slate-700 dark:text-white shadow-md h-full">

                    </div>

                </div>
                <div className="col-span-2 grid gap-4 ">
                    <Profile/>
                    <PasswordChange/>
                </div>
            </div>
        </div>
    );
}

export default Settings;
