import { createSlice,createAction } from "@reduxjs/toolkit";
export const customersSlice = createSlice({
    name:'customers',
    initialState:{
        customers:{},
    },
    reducers:{
        writeCustomers(state,action){
            state.customers = action.payload
        },


    }

})
export const {writeCustomers} = customersSlice.actions
export default customersSlice.reducer
