import React, {useRef, useState} from 'react';
import ReactCrop from "react-image-crop";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import '../assets/styles/custom-image-crop.css'
import {image64toCanvasRef, extractImageFileExtensionFromBase64 as getExt} from "../commons/imageHandling";

function CropImage({className,image,setImage}) {
    const canvasRef = useRef()
    const selectImage = () => {
        let input=document.createElement("input");
        input.type="file";
        input.accept=".jpeg,.png,.jpg"
        let files=[]
        input.onchange = e=>{
            files=e.target.files;
            setImage(files[0])
            let reader = new FileReader();
            reader.onload = () => {
                setPrevImage(reader.result)
            }
            reader.readAsDataURL(files[0])

        }
        input.click()

    }
    const handleCropComplete = (crop,pixelCrop)=>{
        let canvas = canvasRef.current
        console.log(pixelCrop,crop)
        image64toCanvasRef(canvas,prevImage,pixelCrop)
        let fileName = 'preview'+ getExt(prevImage)
        // console.log(canvas.toDataUrl(fileName))

    }
    const getCroppedImg = ()=>{
        let canvas = canvasRef.current
        const fileName = ''

    }

    if(!className)className='h-full w-full'
    const [crop, setCrop] = useState({
        aspect:1/1,
    })
    const [prevImage,setPrevImage] = useState('')
    return (
        <div className={className}>
            {
                image === ''?
                    <div className="text-center mih-h-200px w-full rounded-full flex items-center flex-col justify-center" onClick={selectImage}>
                        <FontAwesomeIcon icon={solid("cloud-arrow-up")}/>
                        <p className='my-2'>Click to select images</p>
                    </div>:
                    <div className="relative min-h-200px w-full">
                        <ReactCrop crop={crop} onChange={c => setCrop(c)} src={prevImage}
                                   onComplete={handleCropComplete}
                        >
                            <img src={prevImage} />
                        </ReactCrop>
                        <canvas
                            className="absolute h-150px w-150px rounded-ful left-full shadow-md top-50% v-center border-1px ml-2"
                            ref ={canvasRef}
                        >Your browser does not support canvas</canvas>
                        <div id="preview-canvas">

                        </div>
                    </div>


            }


        </div>
    );
}

export default CropImage;
