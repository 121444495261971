import { configureStore } from '@reduxjs/toolkit'
import settingsReducer from "./reducers/settings"
import productsReducer from "./reducers/products";
import productFiltersReducer from "./reducers/productFilters";
import userReducer from './reducers/user'
import customersSliceReducer from "./reducers/customers";
import shippingReducer from "./reducers/shipping";

export const store = configureStore({
    reducer: {
        settings:settingsReducer,
        products:productsReducer ,
        productFilters:productFiltersReducer,
        user:userReducer,
        customers:customersSliceReducer,
        shipping:shippingReducer
    },
})
