import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {formIsValid} from "../commons/basics";
import {NumberInput} from "./Inputs";
import {doc, updateDoc} from "firebase/firestore";
import {db} from "../firebase";
import {message} from "../commons/swal";

function Profile(props) {
    const {profile:user} = useSelector(state => state.user)
    const [editedUser,setEditedUser] = useState({...user})
    const edit = async (event)=>{
        event.preventDefault()
        if(!formIsValid('profile-form'))return
        let me = editedUser
        console.log(me)
        try{
            await updateDoc(doc(db,'admins',editedUser.email),me)
            message({icon:'success',title:'Profile updated'})
        }catch {
            message({icon:'success',title:'Could not update profile'})
        }

    }
    const discard = ()=>{
        setEditedUser(user)
    }
    return (
        <div className="p-8 rounded-md bg-white dark:bg-slate-700 dark:text-white shadow-md">
            <h3>Profile settings</h3>
            <div className="mt-4">
                <form className='grid gap-4' onSubmit={edit} id='profile-form'>
                    <div className="flex flex-col lg:flex-row gap-4">
                        <fieldset className='w-full'>
                            <label className='font-semibold'>
                                First name
                            </label>
                            <input type="text" className="w-full h-8 pl-4 border-1px rounded-md dark:bg-slate-500"
                                   value={editedUser.firstName} id='first-name'
                                   onChange={event => {setEditedUser(user=>{
                                       return {
                                           ...user,
                                           firstName:event.target.value
                                       }
                                   })}}
                            />
                        </fieldset>
                        <fieldset className='w-full'>
                            <label className='font-semibold'>
                                Last name
                            </label>
                            <input type="text" className="w-full h-8 pl-4 border-1px rounded-md dark:bg-slate-500"
                                   value={editedUser.lastName} id='last-name'
                                   onChange={event => {setEditedUser(user=>{
                                       return {
                                           ...user,
                                           lastName:event.target.value
                                       }
                                   })}}
                            />
                        </fieldset>
                    </div>
                    <div className="flex flex-col lg:flex-row gap-4">
                        <fieldset className='w-full'>
                            <label className='font-semibold'>
                                Email
                            </label>
                            <input type="text" className="w-full h-8 pl-4 border-1px rounded-md dark:bg-slate-500"
                                   value={editedUser.email} id='email' disabled

                            />
                        </fieldset>
                        <fieldset className='w-full'>
                            <label className='font-semibold'>
                                Username
                            </label>
                            <input type="text" className="w-full h-8 pl-4 border-1px rounded-md dark:bg-slate-500"
                                   value={editedUser.username} id='username'
                                   disabled
                            />
                        </fieldset>
                    </div>
                    <div className="flex flex-col lg:flex-row gap-4">
                        <fieldset className='w-full'>
                            <label className='font-semibold'>
                                Phone
                            </label>
                            <NumberInput

                                className="w-full h-8 pl-4 border-1px rounded-md dark:bg-slate-500"
                                value={editedUser.phone} id='phone'
                                validateNo={true}
                                handleChange={phone => {setEditedUser(user=>{
                                    return {
                                        ...user,
                                        phone
                                    }
                                })}}
                            />
                        </fieldset>
                        <fieldset className='w-full'>
                            <label className='font-semibold'>
                                Status
                            </label>
                            <div className="flex items-center gap-2">
                                <button
                                    className="h-6 w-16"
                                    type='button'
                                    onClick={()=>{
                                        setEditedUser(user=>{
                                            return {
                                                ...user,
                                                isActive:!user.isActive
                                            }
                                        })
                                    }}
                                >
                                    {
                                        editedUser.isActive?
                                            <span className='w-full h-full inline-block relative bg-primary flex items-center justify-end  rounded-pill '>
                                                <span className="block h-5 w-5 rounded-full bg-white mr-2"></span>

                                            </span> :
                                            <span className='w-full h-full inline-block border-1px flex items-center  rounded-pill '>
                                                <span className="block h-5 w-5 rounded-full bg-slate-500 ml-2"></span>
                                            </span>
                                    }
                                </button>
                                Active
                            </div>

                        </fieldset>
                    </div>
                    <div className='flex justify-end gap-4'>
                        <button className="w-32 h-8  rounded-md border-1px"
                                type='button'
                                onClick={discard}
                        >Discard</button>
                        <button className="w-32 h-8 bg-primary rounded-md text-white">Save</button>
                    </div>
                </form>

            </div>

        </div>

    );
}

export default Profile;
