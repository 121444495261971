import React, {useEffect, useState} from 'react';
import {Chart} from "react-google-charts";
import {useSelector} from "react-redux";
import {doc, getDoc, onSnapshot} from "firebase/firestore";
import {db, dbOrders} from "../firebase";


export const options = {
    pieHole: 0.4,
};

function CategorySales(props) {
    const {categories} = useSelector(state => state.products)
    const [data,setData] = useState([
        ["Category", "Total sales"],
    ])
    const getSales = async(tempSales) => {
        let tempData  = [
            ["Task", "Hours per Day"],
            ["phones", 10],
            ["laptops", 2],
            ["audio", 2],
            ["accessories", 2],
        ]
        let p = {}
        let totalSales = 0
        let products = Object.keys(tempSales)
        for (let i = 0; i < products.length; i++) {
            let product = products[i]
            try{
                let category = (await getDoc(doc(db, 'products', product))).data().category
                if(p[category]){
                    p[category] += tempSales[product]
                }else{
                    p[category] = tempSales[product]
                }

            } catch (e){
            }
        }
        Object.keys(p).forEach(cat=>{
            tempData.push([cat,p[cat]])
        })
        setData(tempData)


    }
    useEffect(()=>{
        onSnapshot(dbOrders,snapshot => {
            let tempSales = {}
            snapshot.docs.forEach(doc=>{
                let products = doc.data().products
                for (let i = 0; i < products.length; i++) {
                    let item = products[i]
                    let product  = tempSales[item.product]
                    if(tempSales[item.product]){
                        tempSales[item.product]  = tempSales[item.product] + item.price
                    }else{
                        tempSales[item.product]  = item.price
                    }
                }
            })
            getSales(tempSales)
        })

    },[])
    return (
        <>
            <h1>Sales by categories</h1>
            <div className="chart-div">
                <Chart
                    chartType="PieChart"
                    data={data}
                    options={options}
                    width={"100%"}
                    // height={"400px"}
                />
            </div>
        </>
    );
}

export default CategorySales;
