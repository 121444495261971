import React, {useEffect, useState} from 'react';
import Pagination from "../../components/Pagination";
import {useNavigate} from "react-router-dom";
import {dateFormatter} from "../../commons/basics";
import {useSelector} from "react-redux";

function Customers(props) {
    const {customers} = useSelector(state => state.customers)
    const [customerPages,setCustomerPages] = useState(1)
    useEffect(()=>{
    },[customers])
    const navigate = useNavigate()
    const seeDetails = (email) => {
        navigate(`/customers/${email}`)
    }
    const [activePage,setActivePage] = useState(1)
    useEffect(()=>{
        let pages = Object.keys(customers).length
        let pageNumber = Math.ceil(pages/8)
        if (pageNumber>=1){
            setCustomerPages(pageNumber)
        }else {
            setCustomerPages(1)
        }
    },[customers])
    return (
        <div className='p-8'>
            <div className="flex">
                <h1 className='text-6'>Customer List</h1>
            </div>
            <div className='mt-4 border-t-1px pt-4'>
                <div className="w-fit lg:w-full p-8 bg-white dark:bg-slate-700 dark:text-white rounded-md">
                    <table className='w-full border-b-2px '>
                        <thead>
                            <tr className='h-16  px-4 text-left'>
                                <th>Email</th>
                                <th>Name</th>
                                <th>Date joined</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Object.values(customers).slice((activePage-1)*8,(activePage-1)*8+8)
                                    .map((customer,index)=>(
                                    <tr className='h-16 px-4 border-t-1px cursor-pointer hover:text-primary'
                                        key={index} onClick={()=>{seeDetails(customer)}}>
                                        <td>{customer.email}</td>
                                        <td>{customer.firstName} {customer.lastName}</td>
                                        <td>{dateFormatter(customer.dateJoined,'short-slash')}</td>
                                        <td>Active</td>
                                    </tr>
                                ))
                            }
                        </tbody>

                    </table>
                    <Pagination activePage={activePage} setActivePage={setActivePage} pageNo={customerPages}/>
                </div>

            </div>
        </div>
    );
}

export default Customers;
