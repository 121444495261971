import {createSlice} from "@reduxjs/toolkit";
export const productFiltersSlice = createSlice({
    name:'productFilters',
    initialState:{
        hotOffers:{},
        justForU:{}
    },
    reducers:{
        writeHotOffers(state,action){
            state.hotOffers = action.payload

        }
    }

})
export const {writeHotOffers} = productFiltersSlice.actions
export default productFiltersSlice.reducer
