import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {dateFormatter} from "../../commons/basics";
import {useSelector} from "react-redux";
import Loader from "../../components/Loader";
import {makeExtensible, removeFromArray} from "../../commons/objects";
import Datepicker from 'flowbite-datepicker/Datepicker';

function OfferDetails(props) {
    const {id} = useParams()
    const {hotOffers} = useSelector(state => state.productFilters)
    const {categories,brands} = useSelector(state => state.products)
    const [offer,setOffer] = useState(undefined)
    const [catList,setCatList] = useState([])
    const [tempOffer,setTempOffer] = useState({

    })
    const [isChanged,setIsChanged] = useState(false)
    const [startDate,setStartDate] = useState('')

    useEffect(()=>{
        if(id){
            try{
                let temp = hotOffers[id]
                setOffer(temp)
                setTempOffer(temp)
                setCatList(temp.categories)
            }catch {}
        }
    },[id,hotOffers])
    useEffect(()=>{
        if(offer){
            setTempOffer(makeExtensible(offer))

        }

    },[offer])
    const manageCat = (cat) => {
        let categories = tempOffer.categories
        if(categories.includes(cat)){
            categories = removeFromArray(categories,cat)
        }else {
            categories.push(cat)
        }
        setTempOffer(offer=>{
            return {...offer,categories}
        })
    }
    const manageBrands = (brand) => {
        let brands = tempOffer.brands
        if(brands.includes(brand)){
            brands = removeFromArray(brands,brand)
        }else {
            brands.push(brand)
        }
        setTempOffer(offer=>{
            return {...offer,brands}
        })
    }


    return (
        <div className='w-90% lg:w-full mx-auto lg:p-8'>
            <div className="flex flex-col lg:flex-row justify-between">
                <h1 className='text-6'>Offer Details: {id}</h1>
                <div>
                    <Link to='/offers'>
                        <button className="px-2 rounded-md border-1px h-8 whitespace-nowrap">
                            <FontAwesomeIcon icon={solid('arrow-left')} className='mr-1'/>
                            Back to offers
                        </button>
                    </Link>

                </div>
                <div></div>
            </div>
            {
                offer?
                <div className="mt-4 border-t-1px pt-4">
                    <div className="flex flex-col lg:flex-row gap-4">
                        <div
                            className="w-full h-16 rounded-md flex items-center gap-4 px-4 bg-white dark:bg-slate-700 dark:text-white">
                            <div className="h-10 w-10 rounded-md flex items-center justify-center">
                                <FontAwesomeIcon icon={solid('clock')}/>
                            </div>
                            <div>
                                <h1>Start date</h1>
                                <p className='flex gap-4'>
                                    {dateFormatter(tempOffer.startDate,'short-slash')}
                                    <button className='h-8 w-8 border-1px rounded-sm'>
                                        <input type="date" className="w-5 cursor-pointer z-1"
                                               value={dateFormatter(tempOffer.startDate,'short-hyphen',true)}
                                               onChange={(event)=>{
                                                   setTempOffer((offer)=>{return {...offer,startDate:new Date(event.target.value).getTime()}})
                                               }}

                                        />
                                    </button>

                                </p>


                            </div>
                        </div>
                        <div
                            className="w-full h-16 rounded-md flex items-center gap-4 px-4 bg-white dark:bg-slate-700 dark:text-white">
                            <div className="h-10 w-10 rounded-md flex items-center justify-center">
                                <FontAwesomeIcon icon={solid('cart-shopping')}/>
                            </div>
                            <div>
                                <h1>Expiry date</h1>
                                <p className='flex gap-4'>
                                    {dateFormatter(tempOffer.endDate,'short-slash')}
                                    <button className='h-8 w-8 border-1px rounded-sm'>
                                        <input type="date" className="w-5 cursor-pointer z-1"
                                               value={dateFormatter(tempOffer.endDate,'short-hyphen',true)}
                                               onChange={(event)=>{
                                                   setTempOffer((offer)=>{return {...offer,endDate:new Date(event.target.value).getTime()}})
                                               }}

                                        />
                                    </button>

                                </p>

                            </div>
                        </div>

                    </div>
                    <div className="flex flex-col lg:grid grid-cols-3 gap-4 mt-4">
                        <div className='grid-col-start-1 col-span-2 grid gap-4'>
                            <div className="p-4 bg-white dark:bg-slate-700 dark:text-white ">
                                <div>
                                    <h1 className='flex justify-between'>
                                        <span>Categories:</span>
                                        <span>
                                        <FontAwesomeIcon icon={solid('angle-down')}/>
                                    </span>
                                    </h1>
                                </div>
                                <ul className='px-2 list-none gap-2'>
                                    {
                                        categories.map(cat=>(
                                            <li className='flex gap-2 items-center' key={cat}>
                                                <>
                                                    {
                                                        tempOffer.categories.includes(cat)?
                                                            <button className='h-5 w-5 border-1px rounded-sm flex items-center justify-center border-primary'
                                                                    onClick={()=>{manageCat(cat)}}
                                                            >
                                                                <FontAwesomeIcon icon={solid('check')} className='text-primary'/>
                                                            </button>
                                                            :
                                                            <button className='h-5 w-5 border-1px rounded-sm flex items-center justify-center'
                                                                    onClick={()=>{manageCat(cat)}}
                                                            >
                                                            </button>
                                                    }

                                                </>
                                                <span>
                                                {cat}
                                            </span>

                                            </li>
                                        ))
                                    }



                                </ul>
                            </div>
                            <div className="p-4 bg-white dark:bg-slate-700 dark:text-white ">
                                <div>
                                    <h1 className='flex justify-between'>
                                        <span>Brands:</span>
                                        <span>
                                        <FontAwesomeIcon icon={solid('angle-down')}/>
                                    </span>
                                    </h1>
                                </div>
                                <ul className='px-2 list-none gap-2'>
                                    {
                                        brands.map(brand=>(
                                            <li className='flex gap-2 items-center' key={brand}>
                                                <>
                                                    {
                                                        tempOffer.brands.includes(brand)?
                                                            <button className='h-5 w-5 border-1px rounded-sm flex items-center justify-center border-primary'
                                                                    onClick={()=>{manageBrands(brand)}}
                                                            >
                                                                <FontAwesomeIcon icon={solid('check')} className='text-primary'/>
                                                            </button>
                                                            :
                                                            <button className='h-5 w-5 border-1px rounded-sm flex items-center justify-center'
                                                                    onClick={()=>{manageBrands(brand)}}
                                                            >
                                                            </button>
                                                    }

                                                </>
                                                <span>
                                                {brand}
                                            </span>

                                            </li>
                                        ))
                                    }



                                </ul>
                            </div>
                        </div>
                        <div className="grid-col-start-1 bg-white dark:bg-slate-700 dark:text-white p-4">
                            <h1 className="text-5 mb-4">
                                Status Orders
                            </h1>
                            <div className='grid gap-4'>
                                <div className='flex gap-2'>
                                    <button className="w-8 h-8 rounded-sm border-1px  hover:text-primary">
                                        <FontAwesomeIcon icon={solid('trash-can')}/>
                                    </button>
                                    {
                                        offer.isHidden?
                                            <button className='h-8 w-8 rounded-sm  hover:text-primary border-1px'
                                                    onClick={()=>{}}
                                            >
                                                <FontAwesomeIcon icon={solid('eye')}/>
                                            </button>
                                            :
                                            <button className='h-8 w-8 rounded-sm  hover:text-primary border-1px'
                                                    onClick={()=>{}}
                                            >
                                                <FontAwesomeIcon icon={solid('eye-slash')}/>
                                            </button>
                                    }
                                </div>
                                <div>
                                    <button className="w-32 rounded-md bg-primary h-8 text-white">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> :
                    <div className="min-h-screen flex items-center">
                        <Loader/>
                    </div>
            }
        </div>
    );
}


export default OfferDetails;
