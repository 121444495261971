import React, {useState} from 'react';

function Filters({setFilterProps,clear:clearFilter}) {
    const statuses = ['--Select--','Pending','Completed','Cancelled']
    const [customerFilters,setCustomerFilters] = useState([])
    const [filters,setFilters] = useState({
        user:'',
            date:'--Select--',
            dateRange:{
            from:'',
                to:''
        },
        totalRange:{
            upto:null,
            from:null,
            range:{
                max:null,
                min:null
            }
        },
        total:'--Select--',
        status:"--Select--"
    })
    const filter = ()=>{
        let q = []
        if(filters.status !== '--Select--'){
            q.push(['status', '==',filters.status])
        }
        if(filters.date !== '--Select--'){
            let min = filters.dateRange.from,max = filters.dateRange.to
            let d = new Date()
            if(filters.date === 'Today'){
                min  = (new Date(`${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()}`)).getTime()
                max = d.getTime()
            }else if(filters.date === 'This month') {
                min  = (new Date(`${d.getFullYear()}-${d.getMonth()+1}-1`)).getTime()
            }else{
                if(filters.dateRange.from !== ''){
                    min = (new Date(filters.dateRange.from)).getTime()
                }
                if(filters.dateRange.to !== ''){
                    max = (new Date(filters.dateRange.to)).getTime()
                }
            }
            if(max === '')d.getTime()
            if(min === '')d.getTime()
            q.push(['date', '>',min],['date', '<',max])
        }
        if(filters.total !== '--Select--'){
            let max = filters.totalRange.upto
            let min = filters.totalRange.from
            if(filters.total === 'Choose range'){
                max = filters.totalRange.range.max
                min = filters.totalRange.range.min
            }
            console.log('min: ',min,',max: ',max)
            if(min && min !== '' )q.push(['totalPrice', '>',parseInt(min)])
            if(max && max !== '' )q.push(['totalPrice', '<',parseInt(max)])
        }
        setFilterProps(q)

    }
    const clear = ()=>{
        setFilters({
            user:'',
            date:'--Select--',
            dateRange:{
                from:'',
                to:''
            },
            totalRange:{
                less:null,
                more:null,
                range:{
                    max:null,
                    min:null
                }
            },
            total:'--Select--',
            status:"--Select--"
        })
        clearFilter()
    }
    return (
        <div className="bg-white px-4 rounded-md w-full  mt-2 py-4 lg:min-w-700px">
            {/*<div className="flex justify-end mb-2">*/}
            {/*    <button className="w-32  lg:w-48 rounded-sm bg-third focus:outline-none h-10 text-white" onClick={()=>{}}>*/}
            {/*        <span className="mr-2"><i className="fas fa-plus"></i></span>*/}
            {/*        Add order*/}
            {/*    </button>*/}
            {/*</div>*/}
            <h2  className="text-4 lg:text-5 pl-4 lg:pl-0 ">Filter by</h2>
            <div className="lg:flex mt-4 justify-between grid-cols-4 pl-4 lg:pl-0">
                {/*<div className="w-full relative mb-4 lg:mb-0">*/}
                {/*    <span className="block">User</span>*/}
                {/*    <input*/}
                {/*        type="text"*/}
                {/*        className="h-10 border-1px rounded-md w-9/10"*/}
                {/*        value={filters.user}*/}
                {/*        onChange={(event)=>{*/}
                {/*            setFilters(filters=>{*/}
                {/*                return {*/}
                {/*                    ...filters,*/}
                {/*                    user:  event.target.value*/}
                {/*                }*/}
                {/*            })*/}
                {/*        }}*/}
                {/*    />*/}
                {/*    <div className="top-full w-9/10 border-1px mt-1px bg-white shadow-md" id="user-selected" >*/}
                {/*        {*/}
                {/*            customerFilters.map(customer=>(*/}
                {/*                <div className="h-10 flex items-center w-full hover:bg-secondary p-4" key={customer}>*/}
                {/*                    {customer}*/}
                {/*                </div>*/}
                {/*            ))*/}
                {/*        }*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="w-full relative mb-4 lg:mb-0">
                    <span className="block">Date</span>
                    <select
                        name=""
                        className=" w-9/10 h-10 border-1px rounded-md text-black"
                        value={filters.date}
                        onChange={(event)=>{
                            setFilters(filters=>{
                                return {
                                    ...filters,
                                    date:  event.target.value
                                }
                            })
                        }}
                    >
                        <option value="--Select--">--Select--</option>
                        <option value="Today">Today</option>
                        <option value="This month">This month</option>
                        <option value="Choose range">Choose range</option>
                    </select>
                    {
                        filters.date === 'Choose range' &&
                        <div className="top-full w-9/10 mt-1px bg-white border-1px shadow-md p-4" >
                            <fieldset className=" w-full">
                                <span className="block">From</span>
                                <input
                                    className="h-10 items-center shadow-sm border-1px w-4/5 rounded-md"
                                    type="date"
                                    value={filters.dateRange.from}
                                    onChange={(event)=>{
                                        setFilters(filters=>{
                                            return {
                                                ...filters,
                                                dateRange: {
                                                    ...filters.dateRange,
                                                    from: event.target.value
                                                }
                                            }
                                        })
                                    }}
                                />

                            </fieldset>
                            <fieldset className=" w-full mt-4">
                                <span className="block">To</span>
                                <input
                                    className="h-10 items-center shadow-sm border-1px w-4/5 rounded-md"
                                    type="date"
                                    value={filters.dateRange.to}
                                    onChange={(event)=>{
                                        setFilters(filters=>{
                                            return {
                                                ...filters,
                                                dateRange: {
                                                    ...filters.dateRange,
                                                    to: event.target.value
                                                }
                                            }
                                        })
                                    }}
                                />

                            </fieldset>

                        </div>
                    }
                </div>
                <div className="w-full relative mb-4 lg:mb-0">
                    <span className="block">Total</span>
                    <select
                        name=""
                        className=" w-9/10 h-10 border-1px rounded-md text-black"
                        value={filters.total}
                        onChange={(event)=>{
                            setFilters(filters=>{
                                return {
                                    ...filters,
                                    total:  event.target.value,
                                    totalRange: {
                                        ...filters.totalRange,
                                        upto: event.target.value === 'Upto...'?filters.totalRange.upto:'',
                                        from: event.target.value === 'More than...'?filters.totalRange.from:'',
                                    }

                                }
                            })
                        }}
                    >
                        <option value="--Select--">--Select--</option>
                        <option value="More than...">More than...</option>
                        <option value="Less than...">Upto...</option>
                        <option value="Choose range">Choose range</option>
                    </select>
                    {
                        filters.total === 'More than...'?
                            <div className="top-full w-9/10 mt-1px bg-white border-1px shadow-md p-4">
                                <fieldset className=" w-full">
                                    <input
                                        className="h-10 items-center shadow-sm border-1px w-4/5 rounded-md"
                                        type="number"
                                        value={filters.totalRange.from}
                                        onChange={(event)=>{
                                            setFilters(filters=>{
                                                return {
                                                    ...filters,
                                                    totalRange: {
                                                        ...filters.totalRange,
                                                        from: event.target.value
                                                    }
                                                }
                                            })
                                        }}
                                    />
                                </fieldset>


                            </div>
                            :filters.total === 'Less than...'?
                            <div className="top-full w-9/10 mt-1px bg-white border-1px shadow-md p-4">
                                <fieldset className=" w-full">
                                    <input
                                        className="h-10 items-center shadow-sm border-1px w-4/5 rounded-md"
                                        type="number"
                                        value={filters.totalRange.upto}
                                        onChange={(event)=>{
                                            setFilters(filters=>{
                                                return {
                                                    ...filters,
                                                    totalRange: {
                                                        ...filters.totalRange,
                                                        upto: event.target.value
                                                    }
                                                }
                                            })
                                        }}
                                    />

                                </fieldset>
                            </div>
                            :filters.total === 'Choose range'?
                                <div className="top-full w-9/10 mt-1px bg-white border-1px shadow-md p-4" >
                                    <fieldset className=" w-full">
                                        <span className="block">Between</span>
                                        <input
                                            className="h-10 items-center shadow-sm border-1px w-4/5 rounded-md"
                                            type="number"
                                            value={filters.totalRange.range.min}
                                            onChange={(event)=>{
                                                setFilters(filters=>{
                                                    return {
                                                        ...filters,
                                                        totalRange: {
                                                            ...filters.totalRange,
                                                            range: {
                                                                ...filters.totalRange.range,
                                                                min: event.target.value
                                                            }
                                                        }
                                                    }
                                                })
                                            }}
                                        />

                                </fieldset>
                                    <fieldset className=" w-full mt-4">
                                    <span className="block">And</span>
                                    <input
                                        className="h-10 items-center shadow-sm border-1px w-4/5 rounded-md"
                                        type="number"
                                        value={filters.totalRange.range.max}
                                        onChange={(event)=>{
                                            setFilters(filters=>{
                                                return {
                                                    ...filters,
                                                    totalRange: {
                                                        ...filters.totalRange,
                                                        range: {
                                                            ...filters.totalRange.range,
                                                            max: event.target.value
                                                        }
                                                    }
                                                }
                                            })
                                        }}
                                    />

                                </fieldset>
                                </div>
                                :''
                    }

                </div>
                <div className="w-full">
                    <span className="block">Status</span>
                    <select name=""
                            className=" w-9/10 h-10 border-1px rounded-md text-black"
                            value={filters.status}
                            onChange={(event)=>{
                                setFilters(filters=>{
                                    return {
                                        ...filters,
                                        status:  event.target.value
                                    }
                                })
                            }}
                    >
                        {
                            statuses.map(status=>(
                                <option key={status} >{status}</option>
                            ))
                        }
                    </select>
                </div>
            </div>
            <div className="mt-4 text-right flex justify-end">
                <button
                    className="w-32 lg:w-48 rounded-sm bg-slate-100 focus:outline-none h-10 w-9/10 mr-4"
                    onClick={clear}
                >Clear filter
                </button>
                <button
                    className="w-32 lg:w-48 rounded-sm bg-primary focus:outline-none h-10 w-9/10"
                    onClick={()=>filter(filters)}
                >Filter</button>
            </div>
        </div>
    );
}

export default Filters;
