import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {formIsValid} from "../commons/basics";
import {signInWithEmailAndPassword} from "firebase/auth";
import {auth} from "../firebase";
import {useSelector} from "react-redux";
import {updatePassword} from 'firebase/auth'
import {message} from "../commons/swal";

function PasswordChange(props) {
    const {profile:user} = useSelector(state => state.user)
    const [oldPassword,setOldPassword] = useState('')
    const [newPassword,setNewPassword] = useState('')
    const [newPassword2,setNewPassword2] = useState('')
    const [oldPasswordShow,setOldPasswordShow] = useState(false)
    const [newPasswordShow,setNewPasswordShow] = useState(false)
    const [newPassword2Show,setNewPassword2Show] = useState(false)
    const change = async()=> {
        document.getElementById('invalid-pass-err').classList.add('hidden')
        document.getElementById('same-pass-err').classList.add('hidden')
        if(!formIsValid('password-form'))return
        if(oldPassword === newPassword){
            document.getElementById('same-pass-err').classList.remove('hidden')
            return        }
        try {
            await signInWithEmailAndPassword(auth,user.email,oldPassword)
            try{
                await updatePassword(auth.currentUser,newPassword)
                message({icon:'success',title:'Password updated'})
                window.location.reload()
            }
            catch {
                message({icon:'error',title:'An error occurred'})
            }

        }catch (e){
            if(e.code && e.code.includes('password')){
                document.getElementById('invalid-pass-err').classList.remove('hidden')
            }else{
                message({icon:'error',title:'An error occurred'})

            }
        }
    }
    useEffect(()=>{
        if (newPassword2Show){
            document.getElementById('password2').type = 'text'
        }else{
            document.getElementById('password2').type = 'password'
        }
    },[newPassword2Show])
    useEffect(()=>{
        if (newPasswordShow){
            document.getElementById('password1').type = 'text'
        }else{
            document.getElementById('password1').type = 'password'
        }
    },[newPasswordShow])
    useEffect(()=>{
        if (oldPasswordShow){
            document.getElementById('password').type = 'text'
        }else{
            document.getElementById('password').type = 'password'
        }
    },[oldPasswordShow])

    return (
        <div className="p-8 rounded-md bg-white dark:bg-slate-700 dark:text-white shadow-md">
            <h3>Change password</h3>
            <div className="mt-4">
                <form className='grid gap-4'  id='password-form' onSubmit={event => event.preventDefault()}>
                    <div className="flex flex-col gap-4">
                        <fieldset className='w-full'>
                            <label className='font-semibold'>
                                Old password
                            </label>
                            <div className="relative">
                                <input type="password" className="w-full h-8 pl-4 border-1px rounded-md dark:bg-slate-500"
                                       value={oldPassword} id='password'
                                       onChange={event => {setOldPassword(event.target.value)}}
                                />
                                <button className="w-8 h-8 right-0 absolute  top-50% v-center"
                                        onClick={()=>setOldPasswordShow(val=>!val)}
                                >
                                    {
                                        oldPasswordShow?
                                            <FontAwesomeIcon icon={solid('eye-slash')}/>:
                                            <FontAwesomeIcon icon={solid('eye')}/>
                                    }
                                </button>
                            </div>
                            <p id='invalid-pass-err' className='text-red-500 text-3 hidden'>Invalid password</p>
                        </fieldset>
                        <fieldset className='w-full'>
                            <label className='font-semibold'>
                                New password
                            </label>
                            <div className="relative">
                                <input type="password" className="w-full h-8 pl-4 border-1px rounded-md dark:bg-slate-500"
                                       value={newPassword} id='password1'
                                       onChange={event => {setNewPassword(event.target.value)}}
                                />
                                <button className="w-8 h-8 right-0 absolute  top-50% v-center"
                                        onClick={()=>setNewPasswordShow(val=>!val)}
                                >
                                    {
                                        newPasswordShow?
                                            <FontAwesomeIcon icon={solid('eye-slash')}/>:
                                            <FontAwesomeIcon icon={solid('eye')}/>
                                    }
                                </button>
                            </div>
                            <p id='same-pass-err' className='text-red-500 text-3 hidden'>New password must be different</p>
                        </fieldset>
                        <fieldset className='w-full'>
                            <label className='font-semibold'>
                                Confirm new password
                            </label>
                            <div className="relative">
                                <input type="password" className="w-full h-8 pl-4 border-1px rounded-md dark:bg-slate-500"
                                       value={newPassword2} id='password2'
                                       onChange={event => {setNewPassword2(event.target.value)}}
                                />
                                <button className="w-8 h-8 right-0 absolute  top-50% v-center"
                                        onClick={()=>setNewPassword2Show(val=>!val)}
                                >
                                    {
                                        newPassword2Show?
                                            <FontAwesomeIcon icon={solid('eye-slash')}/>:
                                            <FontAwesomeIcon icon={solid('eye')}/>
                                    }
                                </button>
                            </div>
                        </fieldset>
                    </div>
                    <div className='flex justify-end gap-4'>
                        <button className="w-32 h-8  rounded-md bg-primary text-white"
                                type='button'
                                onClick={change}
                        >
                            Change
                        </button>
                    </div>
                </form>

            </div>

        </div>
    );
}

export default PasswordChange;
