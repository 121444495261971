import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {dateFormatter} from "../../commons/basics";
import {doc, getDocs, onSnapshot, updateDoc} from "firebase/firestore";
import {db, dbOrders} from "../../firebase";
import Loader from "../../components/Loader";
import {useSelector} from "react-redux";

function OrderDetails(props) {
    const {id} = useParams()
    const {products} = useSelector(state => state.products)
    const [order,setOrder]  = useState(undefined)
    const [status,setStatus] = useState('')
    const [comments,setComments] = useState('')
    const [changed,setChanged] = useState(false)
    useEffect(()=>{
        if(id){
            onSnapshot(doc(db,'orders',id),snapshot => {
                let myOrder = {...snapshot.data(),id:snapshot.id}
                setOrder(myOrder)
                setStatus(myOrder.status)
                setComments(myOrder.comments)

            })
        }
    },[])
    const getImage = (id) => {
        try {
            return products[id].images[0]
        }catch{
            return ''
        }

    }
    const discard = () =>{
        setStatus(order.status)
        setComments(order.comments)

    }
    const editOrder = async () => {
            await updateDoc(doc(db,'orders',order.id),{
                status,comments
            })
    }
    useEffect(()=>{
        if(order){
            if(comments !== order.comments || status !== order.status){
                setChanged(true)
            }else {
                setChanged(false)
            }
        }
    },[comments,status])


    return (
        <>
            {
                order?
                    <div className='w-90% lg:w-full mx-auto lg:p-8'>
                        <div className="flex flex-col lg:flex-row justify-between">
                            <h1 className='text-6'>Orders Details: Order-{order.id}</h1>
                            <div>
                                <Link to='/orders'>
                                    <button className="px-2 rounded-md border-1px h-8 whitespace-nowrap">
                                        <FontAwesomeIcon icon={solid('arrow-left')} className='mr-1'/>
                                        Back to orders
                                    </button>
                                </Link>

                            </div>
                            <div></div>
                        </div>
                        <div className="mt-4 border-t-1px pt-4">
                            <div className="flex flex-col lg:flex-row gap-4">
                                <div className="w-full h-16 rounded-md flex items-center gap-4 px-4 bg-white dark:bg-slate-700 dark:text-white">
                                    <div className="h-10 w-10 rounded-md flex items-center justify-center">
                                        <FontAwesomeIcon icon={solid('cart-shopping')}/>
                                    </div>
                                    <div>
                                        <h1>Order created at</h1>
                                        <p>
                                            {dateFormatter(order.date,'long-slash')}
                                        </p>
                                    </div>
                                </div>
                                <div className="w-full h-16 rounded-md flex items-center gap-4 px-4 bg-white dark:bg-slate-700 dark:text-white">
                                    <div className="h-10 w-10 rounded-md flex items-center justify-center">
                                        <FontAwesomeIcon icon={solid('user')}/>
                                    </div>
                                    <div>
                                        <h1>Name </h1>
                                        <p>
                                            John Doe
                                        </p>
                                    </div>
                                </div>
                                <div className="w-full h-16 rounded-md flex items-center gap-4 px-4 bg-white dark:bg-slate-700 dark:text-white">
                                    <div className="h-10 w-10 rounded-md flex items-center justify-center">
                                        <FontAwesomeIcon icon={solid('cart-shopping')}/>
                                    </div>
                                    <div>
                                        <h1>Email</h1>
                                        <p>
                                            <Link to={`/customers/${order.address.email}`} className='hover:text-primary'>
                                                { order.address.email }
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                                <div className="w-full h-16 rounded-md flex items-center gap-4 px-4 bg-white dark:bg-slate-700 dark:text-white">
                                    <div className="h-10 w-10 rounded-md flex items-center justify-center">
                                        <FontAwesomeIcon icon={solid('cart-shopping')}/>
                                    </div>
                                    <div>
                                        <h1>Contact Number</h1>
                                        <p>
                                            <a href={`tel:${order.address.phone}`} className='hover:text-primary'>
                                                { order.address.phone }
                                            </a>
                                        </p>
                                    </div>
                                </div>

                            </div>
                            <div className="flex flex-col lg:grid grid-cols-3 gap-4 mt-4">
                                <div className="grid-col-start-1 col-span-2 p-4 bg-white dark:bg-slate-700 dark:text-white ">
                                    <table className='w-full border-b-2px '>
                                        <thead>
                                        <tr className='h-16  px-4 text-left'>
                                            <th>Product Image</th>
                                            <th>Product Name</th>
                                            <th>Quantity</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            order.products.map((item,index)=>(
                                                <tr className='h-24 px-4 border-t-1px cursor-pointer hover:text-primary' key={index}>
                                                    <td>
                                                        <div className='h-18 w-18 p-2  bg-gray-100 dark:bg-slate-500 rounded-md'>
                                                            <img src={getImage(item.product)} alt=""/>
                                                            {/*<img src={require('../../assets/images/products/hplaptop3.png')} alt=""/>*/}
                                                        </div>

                                                    </td>
                                                    <td>{item.product}</td>
                                                    <td> {item.quantity}</td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>

                                    </table>

                                </div>
                                <div className="grid-col-start-1 bg-white dark:bg-slate-700 dark:text-white p-4">
                                    <h1 className="text-5 mb-4">
                                        Status Orders
                                    </h1>
                                    <form className='grid gap-4' onSubmit={(event)=>{event.preventDefault()}}>
                                        <fieldset>
                                            <label className='font-semibold'>
                                                Order Id
                                            </label>
                                            <input type="text" className="w-full h-8 pl-4 border-1px rounded-md  dark:bg-slate-500" value={'Order-'+id} readOnly/>
                                        </fieldset>
                                        <fieldset>
                                            <label className='font-semibold'>
                                                Order Status
                                            </label>
                                            <div className="flex gap-2">
                                                <div className="w-50% h-8 pl-4 border-1px rounded-md dark:bg-slate-500 flex items-center"

                                                >
                                                    {status}

                                                </div>
                                                {
                                                    order.status === 'Cancelled'?
                                                        <button className="w-32 rounded-md bg-secondary h-8 text-white"
                                                                onClick={()=>setStatus('Approved')}
                                                        >
                                                            Approve
                                                        </button>
                                                        :
                                                        <>
                                                            {
                                                                order.status === 'Pending' ?
                                                                    <button className="w-32 rounded-md bg-secondary h-8 text-white"
                                                                            onClick={()=>setStatus('Approved')}
                                                                    >
                                                                        Approve
                                                                    </button>
                                                                    :order.status === 'Approved'?
                                                                        <button className="w-32 rounded-md bg-green-400 h-8 text-white"
                                                                                onClick={()=>setStatus('Completed')}
                                                                        >
                                                                            Complete
                                                                        </button>:''

                                                            }
                                                            {
                                                                order.status !== 'Completed' &&
                                                                <button className="w-32 rounded-md border-1px border-red-400 h-8 text-red-400"
                                                                        onClick={()=>setStatus('Cancelled')}
                                                                >
                                                                    Cancel
                                                                </button>
                                                            }
                                                        </>
                                                }

                                            </div>
                                        </fieldset>
                                        <fieldset>
                                            <label className='font-semibold'>
                                                Comments
                                            </label>
                                            <textarea type="text" className="w-full min-h-100px pl-4 border-1px rounded-md custom-scroll dark:bg-slate-500"
                                                      value={comments} onChange={(event)=>setComments(event.target.value)}
                                            />
                                        </fieldset>
                                        <div className='flex gap-2'>
                                            {
                                                changed?
                                                    <>
                                                        <button className="w-32 rounded-md bg-primary h-8 text-white" onClick={editOrder}>Save</button>
                                                        <button className="w-32 rounded-md border-1px h-8 bg-slate-200" onClick={discard}>Discard changes</button>
                                                    </>
                                                    :
                                                        <button className="w-32 rounded-md border-1px h-8" disabled>Save</button>

                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className='min-h-screen-h w-full flex items-center'>
                        <Loader/>
                    </div>
            }
        </>
    );
}

export default OrderDetails;
