import React, {useEffect, useState} from 'react';
import {Outlet, useSearchParams, useNavigate} from 'react-router-dom'
import {onAuthStateChanged, signOut} from 'firebase/auth'
import {auth, db} from "../firebase";
import {doc, getDoc, onSnapshot} from "firebase/firestore";
import {reduceTimeOut, resetTimeOut, writeProfile, writeSettings} from "../store/reducers/user";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../components/Loader";

function IndexPage(props) {
    const {profile:user,session: {timeOut}} = useSelector(state => state.user)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [searchParams,setSearchParams] = useSearchParams()
    const apiKey = searchParams.get('apiKey')
    const oobCode = searchParams.get('oobCode')
    let email = searchParams.get('email')
    const [newUser,setNewUser] = useState(false)
    const [gotUser,setGotUser] = useState(false)
    const getUser = async ()=>{
        try {
            onSnapshot(doc(db, 'admins', email),snapshot => {
                let data = snapshot
                dispatch(writeProfile({...data.data(),email:data.id}))
                setGotUser(true)
            })


        }catch (e){
            console.log(e)
        }
    }
    useEffect(()=>{
        if(apiKey !== null && oobCode !== null && email !== null){
            getUser()
            setNewUser(true)
            setSearchParams({})
        }else {
            onAuthStateChanged(auth,user=>{
                if(user===null){
                    navigate('/auth/login')
                    setGotUser(true)
                }else {
                    email = user.email
                    getUser()
                    if(user.email){
                        onSnapshot(doc(db, 'settings', user.email),snapshot => {
                            let data = snapshot.data()
                            if(!data || (data.email && !data.isActive)){
                                signOut(auth).catch()
                                navigate('/auth/login')
                            }
                            dispatch(writeSettings(data))
                        })
                    }
                }
            })
        }

    },[])
    const setProfile = ()=>{
        setNewUser(false)
        setGotUser(true)
        navigate('auth/set-password')
    }
    useEffect(()=>{
        let path = window.location.pathname.replace('/','')
            .replace('-',' ')
        if(user.access && !user.access.includes(path) && path !== 'settings'){
            navigate('/')
        }
    },[window.location.pathname])
    useEffect(()=>{
        console.log(timeOut)
        if(timeOut <= 0){
            navigate('/auth/login/session-expired')
            dispatch(resetTimeOut)
        }
    },[timeOut])

   return (
        <div>

            <>
                {
                    gotUser?
                        <>
                            {
                                newUser?
                                    <div className='fixed top-0 left-0 h-screen-h w-full flex items-center justify-center bg-slate-100'>
                                        <div className="h-200px w-50% shadow-md bg-white p-4 flex justify-between flex-col">
                                            <div>
                                                <p className="text-5 text-primary">
                                                    Hello, {user.firstName}. Welcome to wincos!
                                                </p>
                                                <p className='mt-4'>
                                                    Let's get you started. Let's get you a username and password
                                                </p>
                                            </div>
                                            <div className="flex justify-end">
                                                <button className="w-32 h-8 bg-primary rounded-md text-white float-right mt-4"
                                                        onClick={setProfile}
                                                >
                                                    Let's go
                                                </button>
                                            </div>

                                        </div>

                                    </div>:
                                    <>
                                        <Outlet/>
                                    </>
                            }
                        </> :
                        <div className='fixed top-0 left-0 w-full h-screen-h bg-slate-100 flex items-center'>
                            <Loader/>
                        </div>
                }
            </>
        </div>
    );
}

export default IndexPage;
