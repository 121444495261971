import React, {useEffect, useState} from 'react';
import Pagination from "../../components/Pagination";
import {useNavigate} from "react-router-dom";
import {dateFormatter} from "../../commons/basics";
import {onSnapshot, query, where, limit, orderBy, startAfter, getDocs, updateDoc, doc} from "firebase/firestore";
import {db, dbOrders} from "../../firebase";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {confirmAction, message} from "../../commons/swal";
import Filters from "../../components/orders/Filters";
import {filterData} from "../../commons/objects";

function Orders(props) {
    const navigate = useNavigate()
    const seeDetails = (id) => {
        console.log('here')
        navigate(`/orders/${id}`)
    }
    const [activePage,setActivePage] = useState(1)
    const [orderPages,setOrderPages] = useState(1)
    const [orders,setOrders] = useState([])
    const [filters,setFilters] = useState([])

    const [filteredOrders,setFilteredOrders] = useState([])

    const clearFilter = ()=>{
        setFilteredOrders(orders)
    }
    const deleteOrder = async (event,id)=>{
        event.stopPropagation()
        if(! await confirmAction('delete'))return
        try {
            await updateDoc(doc(db,'orders',id),{
                isDeleted:true
            })
            message({icon:'success',title:'Order deleted'})
            getDocs()
        }catch (e) {
            message({icon:'error',title:'Error deleting order'})

        }
    }
    const filterOrders = async () => {
        let tempOrders = [...orders]
        try {
            filters.forEach(filter=>{
                console.log(filter)
                tempOrders = filterData(tempOrders,filter)
                console.log(tempOrders)
            })
            setFilteredOrders(Object.values(tempOrders))
            let pages = tempOrders.length
            let pageNumber = Math.ceil(pages/8)
            if (pageNumber>=1){
                setOrderPages(pageNumber)
            }else {
                setOrderPages(1)
            }
        }catch {}

    }
    useEffect(()=>{
       let q =  query(dbOrders,where('isDeleted','==',false))

        onSnapshot(q,snapshot => {
            let tempOrders = []
            let docs = snapshot.docs
            for (let i = 0; i < docs.length; i++) {
                let myDoc = docs[i]
                let order = myDoc.data()
                tempOrders.push({...order, id: myDoc.id,date:order.date.seconds*1000})
            }
            setOrders(tempOrders)

        })
    },[])
    useEffect(()=>{
        filterOrders()
    },[orders,filters])
    const editStatus = async (event,id,status)=>{
        event.stopPropagation()
        try {
            await updateDoc(doc(db,'orders',id),{
                status
            })
        }catch (e) {

        }

    }
    return (
        <div className='p-8' >
            <div className="flex">
                <h1 className='text-6'>Orders List</h1>
            </div>
            <Filters setFilterProps={setFilters} clear={clearFilter}/>
            <div className='mt-4 border-t-1px pt-4'>
                <div className="w-fit lg:w-full p-8 bg-white dark:bg-slate-700 dark:text-white rounded-md">
                    <table className='w-full border-b-2px '>
                        <thead>
                        <tr className='h-16  px-4 text-left'>
                            <th>ID</th>
                            <th>Total</th>
                            <th>Customer Name</th>
                            <th>Date Created</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                            {
                                filteredOrders.slice((activePage-1)*8,(activePage-1)*8+8)
                                    .map((order,index)=>(
                                    <tr className='h-16 px-4 border-t-1px cursor-pointer hover:text-primary'
                                        key={index} onClick={()=>{seeDetails(order.id)}}>
                                        <td>{order.id}</td>
                                        <td>{order.totalPrice}</td>
                                        <td>{order.address.email}</td>
                                        <td>{dateFormatter(parseInt(order.date),'short-slash')}</td>
                                        <td>
                                            {
                                                order.status === 'Pending'?
                                                    <span className=''>
                                                        {order.status}
                                                    </span>
                                                    :order.status === 'Approved'?
                                                        <span className='text-primary/50'>
                                                        {order.status}
                                                    </span>
                                                        : order.status === 'Completed'?
                                                            <span className='text-green-400'>
                                                        {order.status}
                                                    </span>
                                                            :
                                                            <span className='text-red-600'>
                                                    {order.status}
                                                    </span>
                                            }
                                        </td>
                                        <td >
                                            <span className='flex gap-4 items-center text-black'>
                                                 <button
                                                     className='hover:text-primary'
                                                     onClick={event => deleteOrder(event,order.id)}
                                                 >
                                                    <FontAwesomeIcon icon={solid('trash-can')}/>
                                                </button>
                                                <div className="relative dropdown-cont">
                                                    <span className='hover:text-primary'>
                                                        <FontAwesomeIcon icon={solid('ellipsis')}/>
                                                    </span>
                                                    {
                                                        order.status !== 'Completed' &&
                                                        <div
                                                            className="dropdown  absolute  top-full right-full">
                                                            <div className="absolute h-2 bottom-full w-full left-4"></div>
                                                            <div className='w-150px bg-white z-2 shadow-md border-t-1px rounded-md overflow-hidden'>
                                                                <>
                                                                    {
                                                                        order.status === 'Cancelled'?
                                                                            <button className='w-full h-8 hover:bg-primary hover:text-white'
                                                                                    onClick={(event)=>{editStatus(event,order.id,'Approved')}}
                                                                            >
                                                                                Approve
                                                                            </button>
                                                                            :
                                                                            <>
                                                                                {
                                                                                    order.status === 'Pending'?
                                                                                        <button className='w-full h-8 hover:bg-primary hover:text-white'
                                                                                                onClick={(event)=>{editStatus(event,order.id,'Approved')}}
                                                                                        >
                                                                                            Approve
                                                                                        </button>:
                                                                                        <button className='w-full h-8 hover:bg-primary hover:text-white'
                                                                                                onClick={(event)=>{editStatus(event,order.id,'Completed')}}
                                                                                        >
                                                                                            Complete
                                                                                        </button>
                                                                                }
                                                                                <button className='w-full h-8 hover:bg-primary hover:text-white'
                                                                                        onClick={(event)=>{editStatus(event,order.id,'Cancelled')}}
                                                                                >
                                                                                    Cancel
                                                                                </button>
                                                                            </>
                                                                    }
                                                                </>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </span>

                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>

                    </table>
                    <Pagination activePage={activePage} setActivePage={setActivePage} pageNo={orderPages}/>
                </div>

            </div>
        </div>
    );
}

export default Orders;
