import {useEffect, useState} from 'react'
import {
    BrowserRouter as Router,
    Route,
    Routes as Switch,
} from "react-router-dom";
import Home from "./pages/Home";
import SideNav from "./components/SideNav";
import TopNav from "./components/TopNav";
import Products from './pages/products/Products'
import Orders from "./pages/orders/Orders";
import OrderDetails from "./pages/orders/OrderDetails";
import Login from "./pages/auth/Login";
import Customers from "./pages/customers/Customers";
import CustomerDetails from "./pages/customers/CustomerDetails";
import Settings from "./pages/Settings";
import {useDispatch, useSelector} from "react-redux";
import {writeBrands, writeCategories, writeOffers, writeProducts} from "./store/reducers/products";
import ProductDetails from "./pages/products/ProductDetails";
import {addDoc, getDocs, onSnapshot, updateDoc,doc} from 'firebase/firestore'
import {dbProducts, dbOffers, dbProdBrands, db, dbCustomers, dbShipping} from "./firebase";
import Offers from "./pages/offers/Offers";
import OfferDetails from "./pages/offers/OfferDetails";
import {getOffers} from "./commons/manageOffers";
import {writeHotOffers} from "./store/reducers/productFilters";
import Banners from "./pages/Banners";
import Admins from './pages/Admins'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import IndexPage from "./pages/IndexPage";
import SetPassword from "./pages/auth/SetPassword";
import {writeCustomers} from "./store/reducers/customers";
import {reduceTimeOut, resetTimeOut,writeSessionStarted} from "./store/reducers/user";
import ShippingFee from "./pages/ShippingFee";
import {writeShippingFees} from "./store/reducers/shipping";

function App() {
    const {products,offers} = useSelector(state => state.products)
    const {settings,session:{started}} = useSelector(state => state.user)
    const dispatch = useDispatch()
    const [sessionInterval,setSessionInterval] = useState('')
    const updateBrands = async (brands)=>{
        let myBrands = (await getDocs(dbProdBrands)).docs
        myBrands = myBrands.map(brand=>brand.data().name)
        let newBrands = brands.filter(brand=>!myBrands.includes(brand))
        for (let i = 0; i < newBrands.length ; i++) {
            let brand = newBrands[i]
             await addDoc(dbProdBrands,{
                 name:brand
             })

        }
    }
    const fetchData = async()=>{
        let products = {}
        let offers = {}
        let customers = {}

        onSnapshot(dbProdBrands, snapshot=>{
            let cats = []
            for (let i = 0; i < snapshot.docs.length; i++) {
                let doc = snapshot.docs[i]
                cats.push(doc.data().name)
            }
            dispatch(writeBrands(cats))

        })
        onSnapshot(dbProducts,snapshot => {
                let dbDoc = doc
            for (let i = 0; i < snapshot.docs.length; i++) {
                let doc = snapshot.docs[i]
                products[doc.id] = doc.data()
            }
            dispatch(writeProducts(products))
            let categories  = []
            let brands = []
            Object.values(products).forEach(prod=>{
                if(!categories.includes(prod.category)){
                    categories.push(prod.category)
                }
                if(!brands.includes(prod.brand)){
                    brands.push(prod.brand)
                }
            })
            dispatch(writeCategories(categories))
            updateBrands(brands)

        })
        onSnapshot(dbCustomers,snapshot => {
            for (let i = 0; i < snapshot.docs.length; i++) {
                let doc = snapshot.docs[i]
                customers[doc.id] = {...doc.data(),email:doc.id}
            }
            dispatch(writeCustomers(customers))

        })
        onSnapshot(dbOffers,snapshot=>{
            for (let i = 0; i < snapshot.docs.length; i++) {
                let doc = snapshot.docs[i]
                offers[doc.id] = doc.data()
            }
            dispatch(writeOffers(offers))

        })
        onSnapshot(dbShipping,snapshot=>{
            let shippingFees = {}
            for (let i = 0; i < snapshot.docs.length; i++) {
                let doc = snapshot.docs[i]
                shippingFees[doc.id] = {...doc.data(),id:doc.id}
            }
            dispatch(writeShippingFees(shippingFees))

        })

    }
    useEffect(()=>{
        fetchData()
    },[])
    useEffect(()=>{
        if(offers && products){
            let temptOffers = getOffers(offers,products)
            dispatch(writeHotOffers(temptOffers))
        }
    },[products,offers])
    const {darkMode} = useSelector(state => state.settings)
    useEffect(()=>{
        let html =document.getElementsByTagName('html')[0]
        if(settings.darkMode){
            html.classList.add('dark')
        }else {
            html.classList.remove('dark')
        }
    },[settings])

    const hideAlert = (id) => {
        let myAlert = document.getElementById(id)
        myAlert.classList.replace('animate__fadeInUp','animate__fadeOutDown')
        setTimeout(()=>{
            myAlert.classList.add('hidden')

        },1000)


    }
    const manageIsOnline = (isOnline) =>{
        let myAlert = isOnline? document.getElementById('online-alert'):
            document.getElementById('offline-alert')
        let otherAlert = !isOnline? document.getElementById('online-alert'):
            document.getElementById('offline-alert')
        otherAlert.classList.add('hidden')
        myAlert.classList.remove('hidden')
        if(isOnline){
            setTimeout(()=> {
                hideAlert('online-alert')
            },2000)
        }

    }


    useEffect(()=>{
        window.addEventListener('online', () => manageIsOnline(true));
        window.addEventListener('offline', () => manageIsOnline());
    },[])

    const  reduceSession = async ()=>{
        if(!window.location.href.includes('auth')){
            console.log('reducing')
            dispatch(reduceTimeOut())
        }
    }
    const  resetSession = ()=>{
        if(!window.location.href.includes('auth')){
            dispatch(resetTimeOut)
        }
    }

    useEffect(()=>{
        setTimeout(()=>{
            if(!started){
                dispatch(writeSessionStarted())
                window.addEventListener('mousemove',()=>{
                    resetSession()
                })
                window.addEventListener('input',()=>{
                    resetSession()
                })
                clearInterval(sessionInterval)
                // let invterval = setInterval(reduceSession,1000)
                // console.log(invterval)
            }
        },4000)
    },[started])






  return (
    <div className="App">
      <Router >
          <div className="flex min-h-screen-h bg-slate-100 dark:bg-slate-900 dark:text-white">
              <SideNav/>
              <div className='w-full relative h-full'>
                  <TopNav/>
                  <div className='z-10 w-full pt-18 h-screen-h overflow-auto'>
                      <Switch>
                          <Route path='/orders/:id' element={<OrderDetails/>}/>z
                          <Route path='/products/:id' element={<ProductDetails/>}/>
                          <Route path='/offers/:id' element={<OfferDetails/>}/>
                          <Route path='/customers/:email' element={<CustomerDetails/>} />
                          <Route path='/auth/login' element={<Login/>} />
                          <Route path='/auth/login/:session' element={<Login/>} />
                          <Route path='/' element={<IndexPage/>}>
                              <Route path='/' element={<Home/>}/>
                              <Route path='/products' element={<Products/>}/>
                              <Route path='/shipping-fee' element={<ShippingFee/>}/>
                              <Route path='/orders' element={<Orders/>}/>
                              <Route path='/offers' element={<Offers/>}/>
                              <Route path='/auth/set-password' element={<SetPassword/>} />
                              <Route path='/customers' element={<Customers/>} />
                              <Route path='/admins' element={<Admins/>} />
                              <Route path='/banners' element={<Banners/>} />
                              <Route path='/settings' element={<Settings/>} />
                          </Route>

                      </Switch>
                  </div>
                  <div>
                      <div className="fixed h-16 bottom-4 w-300px left-20% bg-slate-400 dark:bg-slate-500
                                z-10 rounded-md flex items-center justify-between px-4 text-white animate__animated
                                animate__fadeInUp hidden" id='offline-alert'>
                                  <span className='relative'>
                                      <FontAwesomeIcon icon={solid('wifi')}/>
                                      <FontAwesomeIcon icon={solid('exclamation')} className='absolute left-50% top-50% vh-center border-1px border-slate-400'/>
                                  </span>
                          <span> You went offline</span>
                          <button className="h-8 w-8" onClick={()=>hideAlert('offline-alert')}>
                              <FontAwesomeIcon icon={solid('xmark')}/>
                          </button>
                      </div>
                      <div className="fixed h-16 bottom-4 w-300px left-20% bg-white dark:bg-slate-500
                                z-10 rounded-md flex items-center justify-between px-4 overflow-hidden shadow-md
                                animate__animated animate__fadeInUp hidden" id='online-alert'>
                                  <span className='relative'>
                                      <FontAwesomeIcon icon={solid('wifi')} className='text-green-500'/>
                                  </span>
                          <span> Back online</span>
                          <button className="h-8 w-8">
                              <FontAwesomeIcon icon={solid('xmark')}/>
                          </button>
                          <div className="absolute h-1 w-full bottom-0 left-0 bg-green-500"></div>
                      </div>
                  </div>
              </div>
          </div>

      </Router>

    </div>
  );
}

export default App;
