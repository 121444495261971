import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {confirmAction, message} from "../../commons/swal";
import Pagination from "../../components/Pagination";
import AddProduct from "../../components/AddProduct";
import {useDispatch, useSelector} from "react-redux";
import {filterData, makeExtensible} from "../../commons/objects";
import {priceFormatter} from "../../commons/basics";
import {Link} from "react-router-dom";
import {deleteDoc, doc} from 'firebase/firestore'
import {db} from "../../firebase";
import {writeProducts} from "../../store/reducers/products";

function Products(props) {
    const dispatch = useDispatch()
    const {products,brands,categories} = useSelector(state => state.products)
    const [prodView,setProdView] = useState('grid')
    const [productPages,setProductPages] = useState(1)
    const [activePage,setActivePage] = useState(1)
    const [productList,setProductList] = useState([])
    const [showEditProduct,setShowEditProduct] = useState(false)
    const [selectedCat,setSetSelectedCat] = useState('')
    const [selectedBrand,setSetSelectedBrand] = useState('')
    const [editProd,setEditProd] = useState(false)
    const [tempProdList,setTempProdList] = useState([])
    const [selectedProdId,setSelectedProdId] = useState(undefined)
    const expandFilter = (id) => {
        document.getElementById(id).classList.toggle('expanded-filter')
    }

    useEffect(()=>{
        if(products){
            let tempProds = makeExtensible(products)
            if(selectedBrand !== ''){
                tempProds = filterData(tempProds,['brand', '==', selectedBrand])
            }
            if(selectedCat !== ''){
                tempProds = filterData(tempProds,['category', '==', selectedCat])
            }
            let pageNumber = Math.round(Object.values(tempProds).length/8)
            if (pageNumber>=1){
                setProductPages(pageNumber)
            }else {
                setProductPages(1)
            }
            setTempProdList(Object.values(tempProds))
        }


    },[products,selectedCat,selectedBrand])
    useEffect(()=>{
        let startIndex = (activePage-1)*8
        setProductList(tempProdList.slice(startIndex,startIndex+8))
    },[activePage,tempProdList])

    const truncateName = (name) => {
        return name.trim().length>27? name.slice(0,27)+'...':name
    }
    const deleteProduct = async (id) => {
      if(await confirmAction('delete product')){
          try{
              await deleteDoc(doc(db,'products',id))
              message({icon:'success',title:'Product deleted'})
              let tempProds = makeExtensible(products)
              delete tempProds[id]
              dispatch(writeProducts(tempProds))

          }catch (e) {
              message({icon:'error',title:'Error deleting product'})
          }
      }
    }
    const editProduct = (id) => {
      setSelectedProdId(id)
        setShowEditProduct(true)
    }
    useEffect(()=>{
        if(!showEditProduct)setSelectedProdId(undefined)

    },[showEditProduct])
    return (
        <div className='p-4'>
            {
                showEditProduct &&
                <AddProduct change={setShowEditProduct} selectedId={selectedProdId}/>
            }
            <div className="grid lg:flex justify-between w-90% mx-auto items-center gap-4">
                <h1 className='text-6'>Products</h1>
                <div className='p-1 border-primary border-1px flex items-center'>
                    {
                        ['grid','list'].map(view=>(
                            <span key={view} className='flex items-center'>
                                {
                                    view === prodView?
                                        <button className="w-150px h-8 bg-primary rounded-md flex gap-1 items-center justify-center">
                                            {
                                                view === 'grid'?
                                                <img src={require('../../assets/images/icons/grid.png')} className='h-24px w-auto' alt=''/>
                                                    :
                                                <FontAwesomeIcon icon={solid('list')}/>
                                            }
                                            {view} view
                                        </button>
                                        :
                                        <button className="w-150px h-8 rounded-md flex gap-1 items-center justify-center" onClick={()=>{setProdView(view)}}>
                                            {
                                                view === 'grid'?
                                                    <img src={require('../../assets/images/icons/grid.png')} className='h-24px w-auto' alt=''/>
                                                    :
                                                    <FontAwesomeIcon icon={solid('list')}/>
                                            }
                                            {view} view
                                        </button>
                                }
                            </span>
                        ))
                    }

                </div>
                <div>
                    <button className="w-150px h-8 rounded-md flex gap-1 items-center justify-center bg-primary text-white" onClick={()=>{setShowEditProduct(true)}}>
                        Add product
                    </button>

                </div>

            </div>
            <div className="grid lg:flex border-t-1px mt-10 py-4 gap-4 w-90% md:w-full mx-auto">
                <div className='w-full lg:min-w-400px lg:w-400px grid gap-4 h-fit'>
                    <div className="flex items-center border-1px rounded-md justify-between p-4 bg-white dark:bg-slate-700 dark:text-white">
                        <span className="font-semibold text-5">Filter</span>
                        <button className="h-8 w-24 rounded-md bg-primary text-white"
                                onClick={()=>{setSetSelectedCat('');setSetSelectedBrand('')}}
                        >
                            Reset
                        </button>
                    </div>
                    <div className='border-1px p-4 rounded-md dropdown-cont cursor-pointer bg-white dark:bg-slate-700 dark:text-white'>
                        <div className="flex items-center justify-between" onClick={()=>{expandFilter('filter-cat')}} id='filter-cat'>
                            <span className="font-semibold text-5">Categories</span>
                            <span className="capitalize">{selectedCat}</span>
                            <button className="h-8 w-24 text-right" >
                                <FontAwesomeIcon icon={solid('angle-down')}/>
                            </button>
                        </div>
                        <div className="filter-options grid hidden">
                            {
                                categories.map(cat=>(
                                    <span className='py-2 hover:text-primary cursor-pointer capitalize' key={cat}
                                          onClick={()=>{setSetSelectedCat(cat);expandFilter('filter-cat')}}
                                    >
                                        {cat}
                                    </span>
                                ))
                            }
                        </div>
                    </div>
                    <div className='border-1px p-4 rounded-md dropdown-cont cursor-pointer bg-white dark:bg-slate-700 dark:text-white'>
                        <div className="flex items-center justify-between" onClick={()=>{expandFilter('filter-brand')}} id='filter-brand'>
                            <span className="font-semibold text-5">Brands</span>
                            <span className="capitalize">{selectedBrand}</span>
                            <button className="h-8 w-24 text-right" >
                                <FontAwesomeIcon icon={solid('angle-down')}/>
                            </button>
                        </div>
                        <div className="filter-options grid hidden">
                            {
                                brands.map(brand=>(
                                    <span className='py-2 hover:text-primary cursor-pointer capitalize' key={brand}
                                          onClick={()=>{setSetSelectedBrand(brand);expandFilter('filter-brand')}}
                                    >
                                        {brand}
                                    </span>
                                ))
                            }
                        </div>
                    </div>


                </div>
                <div className='w-fit'>
                        {
                            productList.length>0?
                            <div className='flex flex-wrap gap-4 justify-center md:justify-start'>
                                {
                                    productList.map((product,index)=>(
                                        prodView === 'grid'?
                                            <div key={index} className='p-2 rounded-md border-1px bg-white dark:bg-slate-700 dark:text-white'>
                                                <Link to={ `/products/${product.id}`}>
                                                    <div className="h-250px w-250px bg-gray-100 dark:bg-slate-500 flex items-center justify-center">
                                                        {
                                                            !product.images || (product.images[0] === '' || product.images.length === 0)?
                                                                <img src={require('../../assets/images/products/phone.png')} alt="" className='w-90% h-auto'/>:
                                                                <img src={product.images[0]} alt="" className='w-90% h-auto max-h-90%'/>
                                                        }
                                                    </div>
                                                </Link>
                                                <div>
                                                    <h1 className='dropdown-cont relative'>
                                                        {truncateName(product.name)}
                                                        <span className='absolute top-0 left-0 dropdown z-1 w-fit whitespace-nowrap bg-white shadow-md p-1 font-bold'>
                                                            {product.name}
                                                        </span>
                                                    </h1>
                                                    <div className="my-2 text-5  text-primary-light">
                                                        Kshs {priceFormatter(product.price)}
                                                    </div>
                                                    <div className="mt-4 flex gap-2 justify-center">
                                                        <button className="h-8 w-24 rounded-md border-1px"
                                                                onClick={()=>{editProduct(product.id)}}>
                                                            <FontAwesomeIcon icon={solid('pencil')}/>
                                                        </button>
                                                        <button className="h-8 w-24 rounded-md border-1px"
                                                                onClick={()=>{deleteProduct(product.id)}}>
                                                            <FontAwesomeIcon icon={solid('trash-can')}/>
                                                        </button>

                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div key={index} className='w-full h-24 rounded-md p-4 bg-white dark:bg-slate-700 dark:text-white items-center flex justify-between'>
                                                <div className="h-full flex gap-4 items-center">
                                                    <Link to={ `/products/${product.id}`} className='flex gap-2'>
                                                        <div className="h-18 w-18 rounded-md bg-gray-100 dark:bg-slate-500 flex items-center justify-center">
                                                            {
                                                                !product.images || (product.images[0] === '' || product.images.length === 0)?
                                                                    <img src={require('../../assets/images/products/hplaptop3.png')} alt="" className='w-90% h-auto'/>:
                                                                    <img src={product.images[0]} alt="" className='w-90% h-auto'/>
                                                            }
                                                        </div>
                                                        <div>
                                                            <h1>{product.name}</h1>
                                                            <div className="mt-2">
                                                                <div>
                                                                    Kshs {priceFormatter(product.price)}
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className='flex gap-4 items-center'>
                                                    <div className='flex items-center gap-2'>
                                                        <button className="w-10 h-5 rounded-pill border-1px">
                                                            {
                                                                true?
                                                                    <span className="block h-3 w-3 bg-primary rounded-full float-right mr-1"></span>
                                                                    :
                                                                    <span className="block h-3 w-3 bg-gray-300 rounded-full ml-1"></span>
                                                            }
                                                        </button>
                                                        Available
                                                    </div>
                                                    <div className="flex gap-2 justify-center items-center">
                                                        <button className="h-8 w-10 rounded-md border-1px"
                                                                onClick={()=>{editProduct(product.id)}}>
                                                            <FontAwesomeIcon icon={solid('pencil')}/>
                                                        </button>
                                                        <button className="h-8 w-10 rounded-md border-1px"
                                                                onClick={()=>{deleteProduct(product.id)}}>
                                                            <FontAwesomeIcon icon={solid('trash-can')}/>
                                                        </button>

                                                    </div>

                                                </div>

                                            </div>
                                    ))
                                }

                            </div>
                                :
                                <div className='p-4 text-center w-full text-5'>
                                    No products found

                                </div>
                        }
                    {
                        productList.length>0 &&
                        <Pagination activePage={activePage} setActivePage={setActivePage} pageNo={productPages}/>
                    }
                </div>
            </div>
        </div>
    );
}

export default Products;
