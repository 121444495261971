import { initializeApp } from "firebase/app";
import {getFirestore,collection} from 'firebase/firestore'
import {getStorage,ref as storageRef,uploadBytes} from 'firebase/storage'
import {getAuth} from 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyC9Ozp3GVCrqnGb8XvOmpF3wlR1nyk3_dc",
    authDomain: "wincos-test.firebaseapp.com",
    projectId: "wincos-test",
    storageBucket: "wincos-test.appspot.com",
    messagingSenderId: "101536448181",
    appId: "1:101536448181:web:aed11040fa51b8edc708fc",
    measurementId: "G-S0GZWJZX19"
};

export const app = initializeApp(firebaseConfig);
export const db =  getFirestore()
export const transactions = collection(db,'transactions')
export const dbProducts = collection(db,'products')
export const dbOffers = collection(db,'offers')
export const dbOrders = collection(db,'orders')
export const dbBanners = collection(db,'banners')
export const dbAdmins = collection(db,'admins')
export const dbProdBrands = collection(db,'product-brands')
export const dbCustomers = collection(db,'users')
export const dbShipping = collection(db,'shipping-fee')
export const storage = getStorage()
export const auth = getAuth()
export {storageRef,uploadBytes}
