import React,{useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {dateFormatter} from "../commons/basics";
import Pagination from "../components/Pagination";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {confirmAction, message} from "../commons/swal";
import {addDoc, collection, deleteDoc, doc, updateDoc} from "firebase/firestore";
import {db, dbShipping} from "../firebase";
import AddShippingFee from "../components/AddShippingFee";

function ShippingFee(props) {
    const {shippingFees} = useSelector(state => state.shipping)
    const [fee,setFee] = useState(undefined)
    const [feePages,setFeePages] = useState(1)
    const [showEdit,setShowEdit] = useState(false)
    const editFee = (id)=>{
        if(id){
            if(shippingFees[id]){
                setFee(shippingFees[id])
            }
        }
        setShowEdit(true)

    }
    const deleteFee = async (id)=>{
        if(await confirmAction('delete fee')){
            try{
                await deleteDoc(doc(db, 'shipping-fee', id))
                message({icon:'success',title:"shipping fee deleted"})
            }catch {
                message({icon:'error',title:"An error occurred"})
            }
        }
    }
    useEffect(()=>{
        if(!showEdit){
            setFee(undefined)
        }
    },[showEdit])

    const [activePage,setActivePage] = useState(1)
    useEffect(()=>{
        let pages = Object.keys(shippingFees).length
        let pageNumber = Math.ceil(pages/8)
        if (pageNumber>=1){
            setFeePages(pageNumber)
        }else {
            setFeePages(1)
        }
    },[shippingFees])
    return (
        <div className='p-8'>
            {
                showEdit &&
                <AddShippingFee selectedFee={fee} change={setShowEdit}/>
            }
            <div className="flex justify-between items-center">
                <h1 className='text-6'>Shipping Fee</h1>
                <button className="w-150px h-8 rounded-md flex gap-1 items-center justify-center bg-primary text-white"
                        onClick={()=>{setShowEdit(true)}}
                >
                    Add Fee
                </button>
            </div>
            <div className='mt-4 border-t-1px pt-4'>
                <div className="w-fit lg:w-full p-8 bg-white dark:bg-slate-700 dark:text-white rounded-md">
                    <table className='w-full border-b-2px '>
                        <thead>
                        <tr className='h-16  px-4 text-left'>
                            <th>Town</th>
                            <th>Fee</th>
                            <th>Status</th>
                            <th>actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            Object.values(shippingFees).slice((activePage-1)*8,(activePage-1)*8+8).map((fee,index)=>(
                                <tr className='h-16 px-4 border-t-1px cursor-pointer '
                                    key={index} >
                                    <td>{fee.town}</td>
                                    <td>{fee.charges}</td>
                                    <td>
                                        {fee.isActive?'Active':'Inactive'}
                                    </td>
                                    <td>
                                        <span className="flex gap-4">
                                            <button onClick={()=>{editFee(fee.id)}}>
                                                <FontAwesomeIcon icon={solid('pencil')} className='hover:text-primary'/>
                                            </button>
                                            <button onClick={()=>{deleteFee(fee.id)}}>
                                                <FontAwesomeIcon icon={solid('trash-can')} className='hover:text-primary'/>
                                            </button>
                                        </span>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>

                    </table>
                    <Pagination activePage={activePage} setActivePage={setActivePage} pageNo={feePages}/>
                </div>

            </div>

        </div>
    );
}

export default ShippingFee;
