import React from 'react';

function Loader(props) {
    return (
        <div className='w-full flex justify-center'>
            <div className="loader"></div>

        </div>
    );
}

export default Loader;
