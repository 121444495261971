import { createSlice,createAction } from "@reduxjs/toolkit";
export const shippingSlice = createSlice({
    name:'shipping',
    initialState:{
        shippingFees:{},
    },
    reducers:{
        writeShippingFees(state,action){
            state.shippingFees = action.payload
        },


    }

})
export const {writeShippingFees} = shippingSlice.actions
export default shippingSlice.reducer
