import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {formIsValid, validUsername} from "../../commons/basics";
import {doc, setDoc, updateDoc} from "firebase/firestore";
import {auth, db, storage, storageRef, uploadBytes} from "../../firebase";
import {useSelector} from "react-redux";
import {createUserWithEmailAndPassword as createUser} from "firebase/auth";
import {message} from "../../commons/swal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {getDownloadURL} from "firebase/storage";
import CropImage from "../../components/CropImage";

function Login(props) {
    const {profile:user} = useSelector(state => state.user)
    const [username,setUsername] = useState('')
    const [password,setPassword] = useState('')
    const [allSet,setAllSet] = useState(false)
    const [authSet,setAuthSet] = useState(false)
    const [invalidUsername,setInvalidUsername] = useState(false)
    const [image,setImage] = useState('')


    const navigate = useNavigate()
    useEffect(()=>{
        try {
            document.getElementById('top-nav').style.display = 'none'
            document.getElementById('side-nav').style.display = 'none'

        } catch {}
        return()=>{
            document.getElementById('top-nav').style.display = null
            document.getElementById('side-nav').style.display = null
        }

    })
    const setProfile = async (event) => {
        event.preventDefault()
        if(!formIsValid('set-password-form') || invalidUsername)return
        console.log(user)
        try{
            await updateDoc(doc(db,'admins',user.email),{
                username
            })
            await createUser(auth,user.email,password)
            await setDoc(doc(db,'settings',user.email),{
                darkMode:true
            })

            setAuthSet(true)
        }catch (e){
            if(e.message && e.message.includes('email-already-in-use')) {
                message({icon:'error',title:'Email already in use'})
            }else {
                message({icon:'error',title:'Error setting up. Try again'})
            }
        }
    //    Login



    }
    const setProfilePic = async ()=>{
        let ref = storageRef(storage,`admins/${user.email}`)
        try{
            await uploadBytes(ref,image)
            let img = await getDownloadURL(ref)
            await updateDoc(doc(db,'admins',user.email),{
                profilePic:img
            })
        }   catch (e) {
            message({icon:'error',title:'Problem saving images'})
            console.log(e)

        }

    }
    useEffect(()=>{
        setInvalidUsername(!validUsername(username))
    },[username])

    return (
        <div className='h-screen-h bg-white dark:bg-slate-700 dark:text-white overflow-hidden fixed top-0 w-screen-w flex items-center justify-center'>
            {
                !authSet?
                    <div className='fixed top-0 left-0 h-screen-h w-full flex items-center justify-center bg-slate-100'>
                        {
                            allSet?
                            <div className="min-h-200px w-50% shadow-md bg-white p-4 flex justify-between flex-col">
                                <p className="text-5 text-primary">
                                    All set now!
                                </p>
                                <p className=''>
                                    You can set your preferences later in the settings section
                                </p>
                                <div className="flex justify-end gap-2">
                                    <button className="w-150px h-8 bg-primary rounded-md text-white"
                                            onClick={()=>{navigate('/')}}
                                    >
                                        Go to home page
                                    </button>

                                </div>

                            </div>:
                                <div className="min-h-200px w-50% shadow-md bg-white p-4 flex justify-between flex-col">
                                    <p className="text-5 text-primary">
                                        One more thing, Set your profile picture!
                                    </p>
                                    <div className="col-span-2 max-w-full ">
                                        <div
                                            className=" mx-auto min-h-200px w-200px rounded-full border-dotted border-1 "

                                        >
                                            <CropImage image={image} setImage={setImage} className='min-h-200px flex items-center'/>

                                        </div>
                                    </div>
                                    <div className="flex justify-end gap-2">
                                        <button className="w-32 h-8 rounded-md text-primary" onClick={()=>{setAllSet(true)}}>
                                            Skip for now
                                        </button>
                                        {
                                            image !== ''?
                                                <button className="w-32 h-8 bg-primary rounded-md text-white"
                                                        onClick={setProfilePic}
                                                >
                                                    Set
                                                </button>:
                                                <button className="w-32 h-8 border-1px rounded-md bg-slate-100"
                                                         disabled
                                                >
                                                    Set
                                                </button>
                                        }

                                    </div>

                                </div>


                        }
                    </div>
                    :
                    <>
                        <div className="h-1 w-1 bg-red-500 absolute flex top-50% left-50%">

                            <div className="absolute h-screen-w-70 w-screen-w-70 rounded-full top-50% left-50% bg-secondary/[.15] vh-center">
                                <div className="absolute h-75% w-75% rounded-full top-50% left-50% bg-secondary/[.25] vh-center z-1">
                                    <div className="absolute h-50% w-50% rounded-full top-50% left-50% bg-secondary/[.4] vh-center z-2"></div>
                                </div>
                            </div>
                        </div>
                        <div className="w-400px h-500px max-w-90% bg-white dark:bg-slate-700 dark:text-white shadow-md z-2 rounded-md overflow-hidden flex flex-col">
                            <div className="h-18 flex items-center justify-center gap-4 bg-secondary text-white">
                                <img src={require('../../assets/images/favicon.ico')} alt="" className='h-10 w-auto'/>
                                Wincos
                            </div>
                            <div className="px-4 h-full flex items-center justify-center">
                                <form className='grid gap-4' onSubmit={setProfile} id='set-password-form'>
                                    <fieldset>
                                        <label className='font-semibold'>
                                            Username
                                        </label>
                                        <input type="text" className="w-full h-8 pl-4 border-1px rounded-md dark:bg-slate-500"
                                               value={username} onChange={(event)=>setUsername(event.target.value)}
                                               id='username'/>
                                        {
                                            invalidUsername &&
                                            <p className="text-red-400 text-3">
                                                Username can only contain letters, numbers, '-' and '_'
                                            </p>
                                        }
                                    </fieldset>
                                    <fieldset>
                                        <label className='font-semibold'>
                                            Password
                                        </label>
                                        <input type="password" className="w-full h-8 pl-4 border-1px rounded-md dark:bg-slate-500" id='password1'
                                               value={password} onChange={(event)=>setPassword(event.target.value)}
                                        />
                                    </fieldset>
                                    <fieldset>
                                        <label className='font-semibold'>
                                            Confirm Password
                                        </label>
                                        <input type="password" className="w-full h-8 pl-4 border-1px rounded-md dark:bg-slate-500" id='password2'/>
                                    </fieldset>
                                    <div className='text-center'>
                                        <button className="w-32 h-8 bg-primary rounded-md text-white">Set</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </>
            }




        </div>
    );
}

export default Login;
