import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {formIsValid, isEmail, validateInp,} from "../../commons/basics";
import {
    signInWithEmailAndPassword, deleteUser,
    getAuth,sendPasswordResetEmail,setPersistence,browserSessionPersistence
} from "firebase/auth";
import {auth, dbAdmins,db} from "../../firebase";
import {doc, getDoc, getDocs, query, where} from "firebase/firestore";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {message} from "../../commons/swal";
import {resetTimeOut} from "../../store/reducers/user";
import {useDispatch} from "react-redux";

function Login(props) {
    const {session} = useParams()
    const navigate = useNavigate()
    const [email,setEmail] = useState('')
    const [resetEmail,setResetEmail] = useState('')
    const [resetEmailSent,setResetEmailSent] = useState(false)
    const [password,setPassword] = useState('')
    const [adminUnavailable,setAdminUnavailable] = useState(false)
    const [adminInactive,setAdminInactive] = useState(false)
    const [invalidCreds,setInvalidCreds] = useState(false)
    const [showPass,setShowPass] = useState(false)
    const [forgotPassword,setForgotPassword] = useState(false)
    const dispatch = useDispatch()
    const resetPassword = async ()=>{
        validateInp('email')
        if(!isEmail(resetEmail))return
        try{
            await sendPasswordResetEmail(auth,email)
            setResetEmailSent(true)
        }catch {
            message({icon:'error',title:'could not send email'})
        }
    }
    useEffect(()=>{
        try {
            document.getElementById('top-nav').style.display = 'none'
            document.getElementById('side-nav').style.display = 'none'

        } catch {}
        return()=>{
            document.getElementById('top-nav').style.display = null
            document.getElementById('side-nav').style.display = null
        }

    })
    const handleLogin = async (event) => {
        event.preventDefault()
        setInvalidCreds(false)
        if(!formIsValid('login-form'))return
        let myEmail = email
        if(!isEmail(myEmail)){
            let docs  = (await getDocs(query(dbAdmins, where('username', '==', email)))).docs
            if(docs.length>0){
                myEmail = docs[0].id
            }
        }
        setPersistence(auth, browserSessionPersistence)
            .then(() => {
                // Existing and future Auth states are now persisted in the current
                // session only. Closing the window would clear any existing state even
                // if a user forgets to sign out.
                // ...
                // New sign-in will be persisted with session persistence.
                return signInWithEmailAndPassword(auth, email, password);
            })
            .catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
            });
        try{
            let admin = (await getDoc(doc(db, 'admins', myEmail))).data()
            await signInWithEmailAndPassword(auth,myEmail,password)
            dispatch(resetTimeOut)
            if(!admin){
                setAdminUnavailable(true)
                try {
                    await deleteUser(getAuth().currentUser)
                }catch {}
                return
            }
            if(!admin.isActive){
                setAdminInactive(true)
                return
            }
            navigate('/')
        } catch (e) {
            if(e.message && (e.message.includes('wrong') || e.message.includes('invalid'))){
                setInvalidCreds(true)
            }else {
                console.log(e)
            }
        }

    }
    useEffect(()=>{
        let inp = document.getElementById('password1')
        try {
            if(showPass){
                inp.type = 'text'
            }else {
                inp.type = 'password'
            }

        }catch {}
    },[showPass])
    return (
        <div className='h-screen-h bg-white dark:bg-slate-700 dark:text-white overflow-hidden fixed top-0 w-screen-w flex items-center justify-center'>
            <div className="h-1 w-1 bg-red-500 absolute flex top-50% left-50%">

                    <div className="absolute h-screen-w-70 w-screen-w-70 rounded-full top-50% left-50% bg-secondary/[.15] vh-center">
                        <div className="absolute h-75% w-75% rounded-full top-50% left-50% bg-secondary/[.25] vh-center z-1">
                            <div className="absolute h-50% w-50% rounded-full top-50% left-50% bg-secondary/[.4] vh-center z-2"></div>
                        </div>
                    </div>
            </div>
            <div className="w-400px h-500px max-w-90% bg-white dark:bg-slate-700 dark:text-white shadow-md z-2 rounded-md overflow-hidden flex flex-col">
                <div className="h-18 flex items-center justify-center gap-4 bg-secondary text-white">
                    <img src={require('../../assets/images/favicon.ico')} alt="" className='h-10 w-auto'/>
                    Wincos
                </div>
                {
                    forgotPassword?
                        <div className="px-4 h-full flex flex-col items-center justify-center">
                            {
                                !resetEmailSent?
                                    <div>
                                        <div className="text center font-bold text-5">
                                            Enter your email and we&apos;ll send you a reset password link
                                        </div>
                                        <input type="text" className="w-full h-8 pl-4 border-1px rounded-md dark:bg-slate-500 mt-5"
                                               value={resetEmail} id='password1'
                                               onChange={event => {setResetEmail(event.target.value)}}
                                               id='email'
                                        />
                                        <div className='text-center mt-4'>
                                            <button className="w-32 h-8 bg-primary rounded-md text-white"
                                                    onClick={resetPassword}
                                            >
                                                Send
                                            </button>
                                        </div>

                                    </div>:
                                    <div>
                                        <div className="text center font-bold text-5">
                                            Email sent. Check our inbox
                                        </div>

                                    </div>
                            }
                            <div className='mt-8'>
                                <button type='button' className='underline' onClick={()=>setForgotPassword(false)}>
                                    Back to Login
                                </button>
                            </div>
                        </div>:
                        <div className="px-4 h-full flex items-center justify-center">
                            {
                                !adminInactive && !adminUnavailable?
                                    <form className='grid gap-4' onSubmit={handleLogin} id='login-form'>
                                        {
                                            session&&
                                            <div className='text-center mb-4 text-red-500'>
                                                Session expired. login in to continue
                                            </div>
                                        }
                                        <fieldset>
                                            <label className='font-semibold'>
                                                Username or email
                                            </label>
                                            <input type="text" className="w-full h-8 pl-4 border-1px rounded-md dark:bg-slate-500"
                                                   value={email} id='username'
                                                   onChange={event => {setEmail(event.target.value)}}
                                            />
                                        </fieldset>
                                        <fieldset>
                                            <label className='font-semibold'>
                                                Password
                                            </label>
                                            <div className="relative">
                                                <input type="password" className="w-full h-8 pl-4 border-1px rounded-md dark:bg-slate-500"
                                                       value={password} id='password1'
                                                       onChange={event => {setPassword(event.target.value)}}
                                                />
                                                <div className='absolute right-0 mr-4 top-50% v-center'>
                                                    <button className='hover:underline text-primary' onClick={()=>{setShowPass(val=>!val)}} type='button'>
                                                        {
                                                            showPass?
                                                                <FontAwesomeIcon icon={solid('eye-slash')}/>
                                                                :
                                                                <FontAwesomeIcon icon={solid('eye')}/>
                                                        }
                                                    </button>
                                                </div>
                                            </div>
                                        </fieldset>
                                        <div className='flex justify-end -mt-4'>
                                            <button className='hover:underline text-primary' onClick={()=>{setForgotPassword(true)}} type='button'>
                                                Forgot password
                                            </button>
                                        </div>
                                        {
                                            invalidCreds &&
                                            <p className="text-3 text-red-400 -mt-3">
                                                Invalid credentials
                                            </p>
                                        }
                                        <div className='text-center'>
                                            <button className="w-32 h-8 bg-primary rounded-md text-white">Login</button>
                                        </div>
                                    </form>:
                                    <div>
                                        {
                                            adminInactive?
                                                <div>
                                                    Your account is currently inactive. Contact admin for support
                                                </div> :
                                                <div>
                                                    Your account has been deleted. Contact admin for support
                                                </div>
                                        }
                                    </div>
                            }
                        </div>
                }
            </div>




        </div>
    );
}

export default Login;
